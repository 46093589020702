import React from 'react';
import { ContestType, Entrant, EventPlatform } from '../lib/model';
import NationalityFlag from './nationality-flag';

// TODO: Update this to use prop for showing nationality flag
const EntrantLocation = ({ event, entrant }: { event: any; entrant: Entrant }) =>
  event.platform === EventPlatform.UTMB || [ContestType.VoteTen].includes(event.contest_type) ? (
    <NationalityFlag country={entrant.athlete?.country || entrant.state_or_country} />
  ) : (
    <div className="text-sm capitalize text-gray-900">
      {entrant.city ? `${entrant.city}, ${entrant.state_or_country}` : entrant.state_or_country}
    </div>
  );

export default EntrantLocation;
