import React from 'react';
import CloseModalButton from './close-modal-button';
import FlashMessage from './flash-message';
import EventCountdown from './event-countdown';

interface PostSubmissionModalProps {
  onClose: () => void;
  isVoteContest: boolean;
  maxPicksPerField: number;
  picksCount: number;
  eventClosesAt: string;
}

const PostSubmissionModal = ({
  onClose,
  isVoteContest,
  maxPicksPerField,
  picksCount,
  eventClosesAt
}: PostSubmissionModalProps) => {
  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      aria-hidden="true"
      className="h-modal fixed inset-0 left-0 right-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-gray-900/60"
    >
      <div className="relative mx-auto mt-16 h-auto w-full max-w-md p-4">
        <div className="relative bg-white shadow">
          <div className="pattern-profile flex items-start justify-between border border-gray-900 p-4 pb-3">
            <h3 className="text-shadow-sm shadow-black/30 text-normal font-semibold text-white">
              Thanks for {isVoteContest ? 'voting' : 'playing'}!
            </h3>
            <CloseModalButton onClick={onClose} />
          </div>
          <div className="space-y-4 border border-y-0 border-gray-900 p-4">
            <p className="text-sm leading-relaxed ">
              Your picks have been saved! You can update your selections as many times as you like
              until{' '}
              {isVoteContest
                ? 'the voting period concludes'
                : 'the event is locked—generally just before the race starts'}
              .
            </p>
            <p className="text-sm leading-relaxed ">
              <EventCountdown closesAt={eventClosesAt} />
            </p>
            {!isVoteContest && picksCount < maxPicksPerField * 2 && (
              <FlashMessage type="info">
                <span className="text-xs">
                  Make {maxPicksPerField} picks per field to maximize your score!
                </span>
              </FlashMessage>
            )}
          </div>
          <div className="pattern-profile flex items-center space-x-2 border border-gray-900 p-4">
            <button
              className="text-shadow-sm shadow-black/30 text-xs text-white underline focus:z-10"
              onClick={onClose}
            >
              Back to Event
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSubmissionModal;
