import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Footer from '../components/footer';
import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import api from '../lib/api';
import Select, { SingleValue } from 'react-select';
import MainContent, { PaddedContent } from '../components/main-content';
import { EventData, EventStatus, League } from '../lib/model';
import UserAvatar from '../components/user-avatar';
import LeagueSettingsForm from '../components/league-settings-form';
import { STATUS_TEXT } from '../components/status-badge';
import { formatDatetime } from '../lib/strings';
import DataTable from 'react-data-table-component';
import { SeasonLeaderboard } from './leaderboards';

function formatEventName(event: EventData): string {
  return `${event.name} ${event.format ? `(${event.format})` : ''}`;
}

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: '2px solid rgba(132, 88, 126, 0.6)',
    padding: 4,
    boxShadow: '0 !important',
    '&:hover': {
      border: '2px solid rgb(132, 88, 126)'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eef766' : '',
    color: state.isSelected ? 'black' : ''
  })
};

const CopyInviteLink: React.FC<{ inviteCode: string }> = ({ inviteCode }) => {
  const [copied, setCopied] = useState(false);
  const inviteLink = `${window.location.origin}/leagues/join/${inviteCode}`;

  return (
    <div className="focus-within:ring-purple-haze-500 ring-purple-haze-100 flex shadow-sm ring-2 ring-inset ring-inset focus-within:ring-2 focus-within:ring-inset">
      <input
        type="text"
        readOnly
        value={inviteLink}
        name="invite-link"
        id="invite-link"
        className="block flex-1 border-0 bg-transparent px-4 py-4 text-xs text-gray-900 placeholder:text-gray-400 focus:ring-0"
      />
      <button
        className="px-4 py-2 font-mono font-medium uppercase text-gray-900"
        onClick={() => {
          navigator.clipboard.writeText(inviteLink);
          setCopied(true);
        }}
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
    </div>
  );
};

const LeagueRoute: React.FC = () => {
  const params = useParams() as { leagueId: string };
  const [searchParams, _] = useSearchParams();
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingEvents, setIsSavingEvents] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(Boolean(searchParams.get('settings')));
  const [showSaved, setShowSaved] = useState(false);
  const [league, setLeague] = useState<League | undefined>(undefined);
  const [events, setEvents] = useState<EventData[]>([]);
  const [leaderboardEvent, setLeaderboardEvent] = useState<
    SingleValue<{ value: string; label: string }>
  >({
    value: '',
    label: 'All League Events'
  });
  const [leagueEvents, setLeagueEvents] = useState<Readonly<{ value: string; label: string }[]>>(
    []
  );

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }

    const fetchLeague = async () => {
      const leagueData = await api(user.accessToken).getLeague(params.leagueId);
      setIsLoading(false);
      setLeague(leagueData);
      setLeagueEvents(
        leagueData.current_season.events.map((e) => ({
          value: e.id.toString(),
          label: formatEventName(e)
        }))
      );
    };

    const fetchEvents = async () => {
      const eventsData = await api(user.accessToken).getEvents(new Date().getFullYear());
      setEvents(eventsData);
    };

    fetchLeague();
    fetchEvents();
  }, [user.isAuthenticated]);

  const saveLeagueEvents = useCallback(async () => {
    setIsSavingEvents(true);
    await api(user.accessToken).selectLeagueEvents(
      league!.uuid,
      league!.current_season.id,
      leagueEvents.map((e) => ({
        event_id: parseInt(e.value)
      }))
    );
    setIsSavingEvents(false);
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 2000);
  }, [league, leagueEvents]);

  const saveLeagueSettings = useCallback(
    async ({ name, avatarUrl }: { name: string; avatarUrl: string }, after: () => void) => {
      setIsSavingSettings(true);
      await api(user.accessToken).updateLeague(league!.uuid, { name, avatar_url: avatarUrl });
      after();
      setIsSavingSettings(false);
    },
    [league, user]
  );

  if (league === undefined) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        title={
          <>
            <img className="mr-3 inline h-10 w-10 rounded" src={league.avatar_url} />
            <span className="text-xl sm:text-2xl">{league.name} League</span>
          </>
        }
        subTitle={
          <div className="mt-4">
            <span>Current Season: {league.current_season.year}</span>
          </div>
        }
      />
      <MainContent>
        <PaddedContent>
          {league.current_user_admin && (
            <>
              <button
                onClick={() => setDisplaySettings(!displaySettings)}
                className="hyperlink mb-8 text-sm underline"
              >
                {displaySettings ? 'Hide' : 'Show'} admin settings
              </button>
              {displaySettings && (
                <>
                  <h3 className="mb-4 text-base font-medium">
                    Manage League <span className="ml-1 text-xs text-gray-500">(admin)</span>
                  </h3>
                  <div className="grid grid-cols-1 gap-x-8 text-sm sm:grid-cols-2">
                    <div className="mb-8">
                      <LeagueSettingsForm
                        isSaving={isSavingSettings}
                        league={league}
                        onSubmit={saveLeagueSettings}
                      />
                      <h4 className="mb-4 mt-8 text-sm font-medium">Add Members</h4>
                      <p className="mb-4">
                        Share this link with friends to invite them to your league.
                      </p>
                      <CopyInviteLink inviteCode={league!.invite_code!} />
                    </div>
                    <div className="mb-8">
                      <h4 className="mb-4 text-sm font-medium">Select Events</h4>
                      <p className="mb-4 leading-relaxed">
                        Choose the events you want to include in your league. You can change this at
                        any time, and the leaderboard will update automatically. Events will be
                        added to the platform throughout the year.
                      </p>
                      <Select
                        className="
                                m-0
                                mt-1
                                block
                                w-full
                                text-sm
                                font-normal text-gray-700"
                        closeMenuOnSelect={false}
                        formatOptionLabel={({ value, label }) => {
                          const event = events.find((event) => event.id === parseInt(value));

                          return (
                            <div>
                              {event && (
                                <img
                                  src={event.image_url}
                                  className="mr-2 inline h-5 w-5 rounded-full"
                                />
                              )}
                              <span>{label}</span>
                            </div>
                          );
                        }}
                        options={events.map((event) => ({
                          value: event.id.toString(),
                          label: formatEventName(event)
                        }))}
                        isMulti
                        onChange={(selected) => {
                          setLeagueEvents(selected);
                        }}
                        value={leagueEvents}
                        placeholder="Select events..."
                        styles={selectStyles}
                      />
                      <button
                        className="ft-btn haze-button mt-4 px-4 py-3"
                        onClick={saveLeagueEvents}
                      >
                        {isSavingEvents ? 'Saving...' : 'Save Events'}
                      </button>
                      {showSaved && (
                        <span className="ml-4 font-mono text-sm uppercase text-gray-600">
                          Saved!
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className="grid grid-cols-2 gap-x-8 text-sm">
            <div className="col-span-2 mb-12">
              <h3 className="mb-6 text-base font-medium">Leaderboard</h3>
              <Select
                className="
                                m-0
                                mb-8
                                block
                                max-w-sm
                                text-sm
                                text-gray-700"
                formatOptionLabel={({ value, label }) => {
                  const event = league.current_season.events.find((event) => event.uuid === value);

                  return (
                    <div>
                      {event && (
                        <img src={event.image_url} className="mr-2 inline h-5 w-5 rounded-full" />
                      )}
                      <span>{label}</span>
                    </div>
                  );
                }}
                options={[
                  {
                    value: '',
                    label: 'All League Events'
                  },
                  ...league.current_season.events
                    .filter((e) => e.status === EventStatus.ResultsPosted)
                    .map((event) => ({
                      value: event.uuid.toString(),
                      label: formatEventName(event)
                    }))
                ]}
                onChange={(selected) => {
                  setLeaderboardEvent(selected);
                }}
                value={leaderboardEvent}
                placeholder="Select event"
                styles={selectStyles}
              />
              <SeasonLeaderboard
                eventId={leaderboardEvent?.value ? leaderboardEvent.value : undefined}
                leagueId={league.uuid}
                noDataComponent={
                  <span className="my-20">
                    Your leaderboard will populate when the first league event has been scored.
                  </span>
                }
                year={league.current_season.year}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
            <div>
              <h3 className="text-base font-medium">Events</h3>
              <DataTable
                className="data-table my-4"
                columns={[
                  {
                    name: 'NAME',
                    sortable: true,
                    selector: (row) => row.name,
                    grow: 3,
                    cell: (row) => (
                      <>
                        <img
                          src={row.image_url}
                          className="mr-3 inline h-8 w-8 rounded-full"
                          referrerPolicy="no-referrer"
                        />
                        <span>
                          <Link className="" to={`/events/${row.uuid}?league=${league.uuid}`}>
                            {formatEventName(row)}
                          </Link>
                        </span>
                      </>
                    )
                  },
                  {
                    name: 'DATE',
                    selector: (row) => row.closes_at,
                    format: (row) => (row.closes_at ? formatDatetime(row.closes_at) : '-'),
                    sortable: true,
                    minWidth: '120px'
                  },
                  {
                    name: 'STATUS',
                    selector: (row) => row.status,
                    cell: (row) => STATUS_TEXT[row.status],
                    sortable: true,
                    hide: 'md'
                  }
                ]}
                customStyles={{
                  rows: {
                    style: {
                      minHeight: 60
                    }
                  },
                  cells: {
                    style: {
                      paddingLeft: '0'
                    }
                  }
                }}
                data={league.current_season.events}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                responsive
                noDataComponent={<span className="my-8 text-sm">No events selected.</span>}
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{ noRowsPerPage: true }}
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
            </div>
            <div>
              <h3 className="text-base font-medium">Members</h3>
              <DataTable
                className="data-table mt-4"
                columns={[
                  {
                    name: 'NAME',
                    sortable: true,
                    selector: (row) => row.user.display_name,
                    grow: 3,
                    cell: (row) => (
                      <>
                        <UserAvatar avatarURL={row.user.profile_image_url} />
                        <span>{row.user.display_name}</span>
                        {row.admin && <span className="ml-2 text-xs text-gray-500">(admin)</span>}
                      </>
                    )
                  },
                  {
                    name: 'JOINED',
                    selector: (row) => row.created_at,
                    format: (row) => formatDatetime(row.created_at),
                    sortable: true,
                    minWidth: '120px'
                  }
                ]}
                customStyles={{
                  rows: {
                    style: {
                      minHeight: 60
                    }
                  },
                  cells: {
                    style: {
                      paddingLeft: '0'
                    }
                  }
                }}
                data={league.league_users}
                defaultSortFieldId={2}
                defaultSortAsc={true}
                responsive
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{ noRowsPerPage: true }}
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
            </div>
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default LeagueRoute;
