import React, { PropsWithChildren } from 'react';

interface RankTextProps {
  rank?: number;
}

function getRankText(rank: number): string {
  switch (rank) {
    case 1:
      return '🥇';
    case 2:
      return '🥈';
    case 3:
      return '🥉';
    case 4:
      return '4️⃣';
    case 5:
      return '5️⃣';
    case 6:
      return '6️⃣';
    case 7:
      return '7️⃣';
    case 8:
      return '8️⃣';
    case 9:
      return '9️⃣';
    case 10:
      return '🔟';
  }

  return rank.toLocaleString();
}

const RankText = (props: PropsWithChildren<RankTextProps>) => (
  <span>{props.rank ? getRankText(props.rank) : ''}</span>
);

export default RankText;
