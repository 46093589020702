import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../components/page-header';
import Footer from '../components/footer';
import MainContent, { PaddedContent } from '../components/main-content';

const StatCard = ({ title, imageUrl, description, path }) => (
  <Link
    className="max-w-sm overflow-hidden shadow-lg hover:cursor-pointer hover:shadow-xl"
    to={`/stats/${path}`}
  >
    <img className="w-full" src={imageUrl} />
    <div className="border-t border-gray-200 px-6 py-6">
      <div className="mb-2 font-mono text-base font-medium">{title}</div>
      <p className="text-sm text-gray-600">{description}</p>
      <button className="ft-btn text-purple-haze-500 mt-6">View {title}</button>
    </div>
  </Link>
);

const Stats = () => {
  return (
    <>
      <PageHeader title="Stats" subTitle="View an assortment of trail running stats" />
      <MainContent>
        <PaddedContent>
          <div className="mt-2 grid grid-cols-1 sm:grid-cols-3">
            <StatCard
              title="Splits"
              description="View historical time splits for trail races. Filter by race, year, and athlete."
              imageUrl="https://cdn.midjourney.com/ca5cbb2a-16d1-4f7c-943c-ea4d8b4288b7/0_0.png"
              path="splits"
            />
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Stats;
