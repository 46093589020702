import { parseISO } from 'date-fns';
import React from 'react';
import Countdown from 'react-countdown';

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  let message = '';

  if (days > 10) {
    return null;
  }

  if (completed) {
    message = 'Picks Locked In (Please Refresh)';
  } else {
    const components = [[seconds, 's']];

    if (minutes > 0 || hours > 0 || days > 0) {
      components.unshift([minutes, 'm']);
    }

    if (hours > 0 || days > 0) {
      components.unshift([hours, 'h']);
    }

    if (days > 0) {
      components.unshift([days, 'd']);
    }

    message = components.reduce((s, c) => s + `${c[0]}${c[1]} `, '');
    message += ' remaining';
  }

  return <p className="mt-4 inline-block text-xs sm:mt-0">{message}</p>;
};

const EventCountdown = ({ closesAt }: { closesAt: string | undefined }) =>
  Boolean(closesAt) ? <Countdown renderer={CountdownRenderer} date={parseISO(closesAt!!)} /> : null;

export default EventCountdown;
