import React from 'react';

const AthleteTeams = ({ teams, showOne = false }) => {
  const teamCount = showOne ? 1 : (teams || []).length;

  return (
    <>
      {(teams || []).slice(0, teamCount).map((team) => (
        <a
          {...{
            onClick: (e) => e.stopPropagation(),
            key: team.id,
            ...(team.is_searchable && { href: `/teams/${team.profile_slug}` })
          }}
        >
          <img
            className="mr-5 inline-block h-10 w-10 rounded-full shadow"
            src={team.logo_urls.thumb}
            title={team.name}
          />
        </a>
      ))}
    </>
  );
};

export default AthleteTeams;
