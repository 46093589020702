import React from 'react';

const UserContext = React.createContext({
  isAuthenticated: false,
  displayName: '',
  accessToken: '',
  picture: '',
  email: ''
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext;
