import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import api, { pickAttributes } from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import Footer from '../components/footer';
import MainContent, { PaddedContent } from '../components/main-content';
import { StravaIcon } from '../components/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../components/loading';
import { UserProfile } from '../lib/model';
import AthleteSelect from '../components/athlete-select';
import FlashMessage from '../components/flash-message';
import Form from '../components/form';

const ConnectWithStravaButton = ({ onClick }) => (
  <button onClick={onClick} className="-ml-[4px]">
    <img src="https://d2gwq01zobnbgs.cloudfront.net/assets/strava/btn_strava_connectwith_orange.svg" />
  </button>
);

const Settings = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [stravaLoading, setStravaLoading] = useState(
    Boolean(searchParams.get('code') && searchParams.get('scope'))
  );
  const [stravaConnected, setStravaConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isSavingAthlete, setIsSavingAthlete] = useState(false);
  const [showAthleteSaved, setShowAthleteSaved] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined);
  const [selectedAthleteId, setSelectedAthleteId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!user.isAuthenticated) return;

    const getUserProfile = async () => {
      const data = await api(user.accessToken).getCurrentUserProfile();

      // If the user hasn't set an email for notifications, default to their auth account email if it exists
      if (!Boolean(data['email']) && Boolean(user.email)) {
        data['email'] = user.email;
      }

      setUserProfile(data);
      setIsLoading(false);
      setStravaConnected(Boolean(data.strava_account));
    };

    getUserProfile();
  }, [user.isAuthenticated]);

  useEffect(() => {
    const connectStrava = async () => {
      try {
        const data = await api(user.accessToken).createStravaAccount(
          searchParams.get('code')!!,
          searchParams.get('scope')!!
        );
        setStravaConnected(true);
      } catch (e) {
      } finally {
        setStravaLoading(false);
      }
    };

    if (user.isAuthenticated && stravaLoading) {
      connectStrava();
    } else {
      setStravaLoading(false);
    }
  }, [user.isAuthenticated, searchParams]);

  const handleSubmit = (attrs: string[]) => {
    const data = pickAttributes(userProfile!!, attrs);
    return api(user.accessToken).updateCurrentUser(data);
  };

  const connectStrava = async () => {
    const data = await api(user.accessToken).authorizeStrava();
    window.location.href = data.redirect_url;
  };

  const disconnectStrava = async () => {
    setStravaLoading(true);
    const data = await api(user.accessToken).deauthorizeStrava();
    setStravaConnected(false);
    setStravaLoading(false);
    setSearchParams({});
  };

  const claimAthlete = async (e) => {
    e.preventDefault();
    setIsClaiming(true);
    const data = await api(user.accessToken).claimAthlete(selectedAthleteId!!);
    navigate(0);
  };

  const saveAthlete = async (e) => {
    e.preventDefault();
    setIsSavingAthlete(true);
    const { personal_url } = userProfile!!.athlete!!;
    const data = await api(user.accessToken).updateAthlete(userProfile!!.athlete!!.uuid, {
      personal_url: personal_url || null
    });
    setIsSavingAthlete(false);
    setShowAthleteSaved(true);
    setTimeout(() => setShowAthleteSaved(false), 1500);
  };

  return (
    <>
      <PageHeader title="Settings" subTitle="Manage your profile settings" />
      <MainContent>
        <PaddedContent>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <div className="mb-12">
                <Form
                  onSubmit={() => handleSubmit(['display_name'])}
                  submitButtonText="Save Profile"
                  disabled={!userProfile?.display_name}
                >
                  <div className="space-y-12">
                    <div className="pb-6">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Player Profile
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        This information will be displayed on public fantasy leaderboards.
                      </p>
                      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="display_name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Display Name
                          </label>
                          <div className="mt-2">
                            <div className="focus-within:ring-purple-haze-500 ring-purple-haze-100 flex shadow-sm ring-2 ring-inset focus-within:ring-2 focus-within:ring-inset sm:max-w-md">
                              <input
                                type="text"
                                value={userProfile?.display_name || ''}
                                onChange={(e) =>
                                  setUserProfile({ ...userProfile!!, display_name: e.target.value })
                                }
                                name="display_name"
                                id="display_name"
                                className="block flex-1 border-0 bg-transparent px-4 py-3 text-xs text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="mb-12">
                <Form
                  onSubmit={() => handleSubmit(['email', 'email_notifications'])}
                  submitButtonText="Save Settings"
                  disabled={!userProfile?.email}
                >
                  <div className="space-y-12">
                    <div className="pb-6">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Notifications
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        Get notified by email when events open for picks.
                      </p>
                      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="display_name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Email
                          </label>
                          <div className="mt-2">
                            <div className="focus-within:ring-purple-haze-500 ring-purple-haze-100 flex shadow-sm ring-2 ring-inset focus-within:ring-2 focus-within:ring-inset sm:max-w-md">
                              <input
                                type="email"
                                value={userProfile?.email || ''}
                                onChange={(e) =>
                                  setUserProfile({ ...userProfile!!, email: e.target.value })
                                }
                                name="display_name"
                                id="display_name"
                                className="block flex-1 border-0 bg-transparent px-4 py-3 text-xs text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 sm:col-span-4">
                          <label className="inline-flex cursor-pointer items-center">
                            <input
                              type="checkbox"
                              checked={userProfile?.email_notifications}
                              onChange={(e) =>
                                setUserProfile({
                                  ...userProfile!!,
                                  email_notifications: !userProfile!!.email_notifications
                                })
                              }
                              className="peer sr-only"
                            />
                            <div className="peer-focus:outline-none after:content-[''] peer-checked:bg-purple-haze-600 peer-focus:ring-purple-haze-300 peer relative h-7 w-14 rounded-full bg-gray-200 after:absolute after:left-[4px] after:top-0.5 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900">Enable</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div>
                <div className="space-y-12">
                  <div className="pb-6">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Athlete Profile
                    </h2>
                    {Boolean(userProfile!!.athlete) ? (
                      <div>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                          Manage the information displayed on{' '}
                          <Link
                            className="hyperlink underline"
                            to={`/athletes/${userProfile!!.athlete.profile_slug}`}
                          >
                            your public athlete profile
                          </Link>
                          .
                        </p>
                        <div className="mt-5 grid grid-cols-1 gap-y-4">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="display_name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Strava
                            </label>
                            <p className="mb-3 mt-1 text-sm leading-6 text-gray-600">
                              {stravaConnected ? (
                                <span>
                                  Your Strava account is connected. You can disconnect and reconnect
                                  at any time.
                                </span>
                              ) : (
                                <span>Connect your Strava account to sync your stats.</span>
                              )}
                            </p>
                            {stravaLoading ? (
                              <Loading />
                            ) : stravaConnected ? (
                              <button
                                onClick={disconnectStrava}
                                className="mt-2 text-sm text-gray-700 underline"
                              >
                                <StravaIcon />
                                Disconnect Strava
                              </button>
                            ) : (
                              <ConnectWithStravaButton onClick={connectStrava} />
                            )}
                          </div>
                          <form onSubmit={saveAthlete} className="mt-4 grid grid-cols-1 gap-y-4">
                            <div className="sm:col-span-4">
                              <label
                                htmlFor="display_name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Personal URL
                              </label>
                              <div className="mt-2">
                                <div className="focus-within:ring-purple-haze-500 ring-purple-haze-100 flex shadow-sm ring-2 ring-inset focus-within:ring-2 focus-within:ring-inset sm:max-w-md">
                                  <input
                                    type="url"
                                    onChange={(e) =>
                                      setUserProfile({
                                        ...userProfile!!,
                                        athlete: {
                                          ...userProfile!!.athlete,
                                          personal_url: e.target.value
                                        }
                                      })
                                    }
                                    value={userProfile!!.athlete.personal_url || ''}
                                    name="personal_url"
                                    id="personal_url"
                                    className="block flex-1 border-0 bg-transparent px-4 py-3 text-xs text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 flex items-center justify-start gap-x-6">
                              <button
                                type="submit"
                                disabled={isLoading || isSavingAthlete}
                                className="ft-btn haze-button px-4 py-3"
                              >
                                {isSavingAthlete ? 'Saving...' : 'Save Profile'}
                              </button>
                              {showAthleteSaved && (
                                <span className="ml-2 font-mono text-sm uppercase text-gray-500">
                                  Saved!
                                </span>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                          Connect your account to your athlete profile.
                        </p>
                        <div className="mt-5 grid grid-cols-1 gap-y-4">
                          {userProfile!!.athlete_claims.some((ac) => ac.status === 'pending') && (
                            <div className="sm:max-w-md">
                              <label
                                htmlFor="display_name"
                                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Pending Review
                              </label>
                              <FlashMessage type="announcement">
                                <span className="text-xs">
                                  Your athlete profile claim is being processed. Thanks!
                                </span>
                              </FlashMessage>
                            </div>
                          )}
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="display_name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Select Athlete
                            </label>
                            <AthleteSelect
                              className="mt-2 sm:max-w-md"
                              onChange={({ value }) => setSelectedAthleteId(value)}
                            />
                            <p className="mt-4 text-xs leading-6 text-gray-600">
                              Connecting your account to an athlete will allow you to manage your
                              public profile, add a personal link, and (optionally) sync your Strava
                              stats for public display on the fantasy platform. We will usually
                              process your claim within 6-12 hours. If we can’t verify that your
                              account is associated with the profile you’ve claimed, we may reach
                              out via Instagram to confirm that the request is authentic.
                            </p>
                          </div>
                          <div className="flex items-center justify-start gap-x-6">
                            <button
                              disabled={isLoading || isClaiming || !selectedAthleteId}
                              className="ft-btn haze-button px-4 py-3"
                              onClick={claimAthlete}
                            >
                              {isClaiming ? '...' : 'Claim Profile'}
                            </button>
                          </div>
                          <div className="sm:col-span-4"></div>
                          <p className=" text-xs leading-6 text-gray-600">
                            If your profile does not yet exist and you’d like to create one, please{' '}
                            <a
                              href="https://freetrail.com/contact"
                              target="_blank"
                              className="hyperlink underline"
                            >
                              send us a message
                            </a>{' '}
                            with your info.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Settings;
