import React, { useState } from 'react';
import Footer from '../components/footer';
import PageHeader from '../components/page-header';
import MainContent, { PaddedContent } from '../components/main-content';

const FAQ = () => {
  const [viewOldPointSystems, setViewOldPointSystems] = useState(false);

  return (
    <>
      <PageHeader title="FAQs" subTitle="Frequently asked questions about the platform" />
      <MainContent>
        <PaddedContent>
          <div className="privacy-text">
            <h1>What is an event series?</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <p>
                An event series consists of multiple related events. Picks for each event are scored
                independently, and a separate leaderboard is displayed on the series page to show
                who scored best cumulatively across all events.
              </p>
            </div>
            <h1 className="mt-8">How are my picks scored?</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <div>
                <p>
                  Picks are scored using simple point systems. These are outlined in the tables
                  below. A player’s <span className="font-medium">Total Score</span> is computed by
                  summing their scores for each field. In the event that multiple participants
                  achieve the same score, the time at which their picks were finalized serves as the
                  tie breaker, with the first to finalize their picks winning out.
                </p>
                <p className="mt-4 font-medium">Last updated: June 29, 2022</p>
              </div>
            </div>
            <p className="mt-8 text-sm font-medium">Five Picks Per Field</p>
            <p>
              You are awarded points for accuracy if your pick finishes in the top 10 in their
              field, with pick #1 accuracy being most valuable and pick #5 accuracy being least
              valuable.{' '}
              <span className="font-medium">
                If your pick finishes outside of the top 10, you are not awarded any points.
              </span>
            </p>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th>Your Pick</th>
                  <th>Scoring Function</th>
                  <th>Point Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-compact">1</td>
                  <td className="td-compact">(10 - ABS(1 - [actual finishing position])) * 50</td>
                  <td className="td-compact">50 - 500</td>
                </tr>
                <tr>
                  <td className="td-compact">2</td>
                  <td className="td-compact">(10 - ABS(2 - [actual finishing position])) * 48</td>
                  <td className="td-compact">96 - 480</td>
                </tr>
                <tr>
                  <td className="td-compact">3</td>
                  <td className="td-compact">(10 - ABS(3 - [actual finishing position])) * 46</td>
                  <td className="td-compact">138 - 460</td>
                </tr>
                <tr>
                  <td className="td-compact">4</td>
                  <td className="td-compact">(10 - ABS(4 - [actual finishing position])) * 44</td>
                  <td className="td-compact">176 - 440</td>
                </tr>
                <tr>
                  <td className="td-compact">5</td>
                  <td className="td-compact">(10 - ABS(5 - [actual finishing position])) * 42</td>
                  <td className="td-compact">210 - 420</td>
                </tr>
                <tr>
                  <td className="td-compact font-medium"></td>
                  <td className="td-compact"></td>
                </tr>
                <tr className="mt-2 border-t border-gray-400">
                  <td></td>
                  <td className="td-compact font-medium">Possible Points Per Field</td>
                  <td className="td-compact font-medium">2300</td>
                </tr>
              </tbody>
            </table>
            <p className="mt-8 text-sm font-medium">Ten Picks Per Field</p>
            <p>
              You are awarded points for accuracy if your pick finishes in the top 15 in their
              field, with pick #1 accuracy being most valuable and pick #10 accuracy being least
              valuable.{' '}
              <span className="font-medium">
                If your pick finishes outside of the top 15, you are not awarded any points.
              </span>
            </p>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th>Your Pick</th>
                  <th>Scoring Function</th>
                  <th>Point Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-compact">1</td>
                  <td className="td-compact">(15 - ABS(1 - [actual finishing position])) * 50</td>
                  <td className="td-compact">50 - 750</td>
                </tr>
                <tr>
                  <td className="td-compact">2</td>
                  <td className="td-compact">(15 - ABS(2 - [actual finishing position])) * 48</td>
                  <td className="td-compact">96 - 720</td>
                </tr>
                <tr>
                  <td className="td-compact">3</td>
                  <td className="td-compact">(15 - ABS(3 - [actual finishing position])) * 46</td>
                  <td className="td-compact">138 - 690</td>
                </tr>
                <tr>
                  <td className="td-compact">4</td>
                  <td className="td-compact">(15 - ABS(4 - [actual finishing position])) * 44</td>
                  <td className="td-compact">176 - 660</td>
                </tr>
                <tr>
                  <td className="td-compact">5</td>
                  <td className="td-compact">(15 - ABS(5 - [actual finishing position])) * 42</td>
                  <td className="td-compact">210 - 630</td>
                </tr>
                <tr>
                  <td className="td-compact">6</td>
                  <td className="td-compact">(15 - ABS(6 - [actual finishing position])) * 40</td>
                  <td className="td-compact">240 - 600</td>
                </tr>
                <tr>
                  <td className="td-compact">7</td>
                  <td className="td-compact">(15 - ABS(7 - [actual finishing position])) * 38</td>
                  <td className="td-compact">266 - 570</td>
                </tr>
                <tr>
                  <td className="td-compact">8</td>
                  <td className="td-compact">(15 - ABS(8 - [actual finishing position])) * 36</td>
                  <td className="td-compact">288 - 540</td>
                </tr>
                <tr>
                  <td className="td-compact">9</td>
                  <td className="td-compact">(15 - ABS(9 - [actual finishing position])) * 34</td>
                  <td className="td-compact">238 - 510</td>
                </tr>
                <tr>
                  <td className="td-compact">10</td>
                  <td className="td-compact">(15 - ABS(10 - [actual finishing position])) * 32</td>
                  <td className="td-compact">192 - 480</td>
                </tr>
                <tr>
                  <td className="td-compact font-medium"></td>
                  <td className="td-compact"></td>
                </tr>
                <tr className="mt-2 border-t border-gray-400">
                  <td></td>
                  <td className="td-compact font-medium">Possible Points Per Field</td>
                  <td className="td-compact font-medium">6150</td>
                </tr>
              </tbody>
            </table>
            <button className="hyperlink underline" onClick={() => setViewOldPointSystems(true)}>
              View Deprecated Point Systems
            </button>
            {viewOldPointSystems && (
              <>
                <p>These point systems were used for events prior to June 29, 2022.</p>
                <p className="mt-8 text-sm font-medium line-through">
                  Five Picks Per Field (Deprecated)
                </p>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th>Achievement</th>
                      <th>Points Awarded</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td-compact">Top 5 exact match</td>
                      <td className="td-compact">70</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 3 exact match</td>
                      <td className="td-compact">50</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 3 in any order</td>
                      <td className="td-compact">40</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 5 in any order</td>
                      <td className="td-compact">35</td>
                    </tr>
                    <tr>
                      <td className="td-compact">First place exact match</td>
                      <td className="td-compact">20</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Second place exact match</td>
                      <td className="td-compact">18</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Third place exact match</td>
                      <td className="td-compact">16</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fourth place exact match</td>
                      <td className="td-compact">14</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fifth place exact match</td>
                      <td className="td-compact">12</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fifth place in your top 5</td>
                      <td className="td-compact">5</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fourth place in your top 5</td>
                      <td className="td-compact">4</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Third place in your top 5</td>
                      <td className="td-compact">3</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Second place in your top 5</td>
                      <td className="td-compact">2</td>
                    </tr>
                    <tr>
                      <td className="td-compact">First place in your top 5</td>
                      <td className="td-compact">1</td>
                    </tr>
                    <tr>
                      <td className="td-compact font-medium"></td>
                      <td className="td-compact"></td>
                    </tr>
                    <tr className="mt-2 border-t border-gray-400">
                      <td className="td-compact font-medium">Total Points Per Field</td>
                      <td className="td-compact font-medium">290</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-8 text-sm font-medium line-through">
                  Ten Picks Per Field (Deprecated)
                </p>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th>Achievement</th>
                      <th>Points Awarded</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td-compact">Top 10 exact match</td>
                      <td className="td-compact">250</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 10 in any order</td>
                      <td className="td-compact">120</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 5 exact match</td>
                      <td className="td-compact">70</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 3 exact match</td>
                      <td className="td-compact">50</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 2 exact match</td>
                      <td className="td-compact">45</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 3 in any order</td>
                      <td className="td-compact">40</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 5 in any order</td>
                      <td className="td-compact">35</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Top 2 in any order</td>
                      <td className="td-compact">30</td>
                    </tr>
                    <tr>
                      <td className="td-compact">First place exact match</td>
                      <td className="td-compact">20</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Second place exact match</td>
                      <td className="td-compact">18</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Third place exact match</td>
                      <td className="td-compact">16</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fourth place exact match</td>
                      <td className="td-compact">14</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fifth place exact match</td>
                      <td className="td-compact">12</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Sixth place exact match</td>
                      <td className="td-compact">10</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Seventh place exact match</td>
                      <td className="td-compact">10</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Eighth place exact match</td>
                      <td className="td-compact">10</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Ninth place exact match</td>
                      <td className="td-compact">10</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Tenth place exact match</td>
                      <td className="td-compact">10</td>
                    </tr>
                    <tr>
                      <td className="td-compact">First place in your top 5</td>
                      <td className="td-compact">6</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Second place in your top 5</td>
                      <td className="td-compact">6</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Third place in your top 5</td>
                      <td className="td-compact">6</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fourth place in your top 5</td>
                      <td className="td-compact">6</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fifth place in your top 5</td>
                      <td className="td-compact">6</td>
                    </tr>
                    <tr>
                      <td className="td-compact">First place in your top 10</td>
                      <td className="td-compact">4</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Second place in your top 10</td>
                      <td className="td-compact">4</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Third place in your top 10</td>
                      <td className="td-compact">4</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fourth place in your top 10</td>
                      <td className="td-compact">4</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Fifth place in your top 10</td>
                      <td className="td-compact">4</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Sixth place in your top 10</td>
                      <td className="td-compact">2</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Seventh place in your top 10</td>
                      <td className="td-compact">2</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Eighth place in your top 10</td>
                      <td className="td-compact">2</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Ninth place in your top 10</td>
                      <td className="td-compact">2</td>
                    </tr>
                    <tr>
                      <td className="td-compact">Tenth place in your top 10</td>
                      <td className="td-compact">2</td>
                    </tr>
                    <tr>
                      <td className="td-compact font-medium"></td>
                      <td className="td-compact"></td>
                    </tr>
                    <tr className="mt-2 border-t border-gray-400">
                      <td className="td-compact font-medium">Total Points Per Field</td>
                      <td className="td-compact font-medium">830</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default FAQ;
