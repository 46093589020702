import React from 'react';
import Footer from '../components/footer';
import PageHeader from '../components/page-header';
import MainContent, { PaddedContent } from '../components/main-content';

const Privacy = () => (
  <>
    <PageHeader title="Privacy Policy" subTitle={`Last updated: April 8, 2022`} />
    <MainContent>
      <PaddedContent>
        <div className="privacy-text">
          <p>
            This Privacy Policy governs use of www.freetrail.com, shop.freetrail.com, and
            fantasy.freetrail.com. These policies may be changed at any time, without notice, at the
            sole discretion of Freetrail, LLC. If you do not agree with the Privacy Policy, please
            do not access this website.
          </p>
          <p>
            <span className="font-medium">1. Collected Data</span>
          </p>
          <ul>
            <li>
              We may collect certain non-personally identifying computer information about you,
              including metadata and “cookies,” “pixel tags”, and similar tracking technologies when
              you visit this site. We collect this information to track activity on this site,
              improve our content, services, products and features, and, ultimately, to enhance your
              experience in using this site.
            </li>
            <li>
              In addition, this site may collect personally identifying information, such as your
              name, email address, website age verification information, user-provided product
              interest inventory information, user information collected from Google Analytics or
              other third-party information aggregator services, and any other information you
              provide to Us in the “Contact” webpage or the “About” webpage. We do not generally
              collect personally identifying information of high security importance, such as social
              security number, telephone number, street address, or driver’s license number. We
              store information submitted through the webpage(s) so that we may respond to your
              inquiries and, if you have requested, we send you our newsletter with promotional
              materials and other relevant information about Freetrail, LLC.
            </li>
            <li>
              Generally, we do not share any personally identifying information with unaffiliated
              third parties. We do provide certain information to our employees, contractors, agents
              and designees as is necessary for completion of any services. We reserve the right to
              disclose any personally identifying information to third parties to the extent we
              believe doing so is required to comply with law, to adhere to requests and legal
              processes of government entities, to prevent, detect, investigate, or comply with
              criminal offenses or attacks on our website, and to protect the rights, property and
              safety of us, our employees and agents, our website visitors, and the public.
            </li>
            <li>
              Email marketing: By providing us with your email address, you are giving us permission
              to send you emails about our store, new products and other updates. You have the
              opportunity to unsubscribe from these emails at any time by clicking the “Unsubscribe”
              button included at the bottom of any email.
            </li>
            <li>
              As with all businesses, there is a possibility we may merge with or be acquired by
              another business. In such instances, the information collected may be transferred to
              the other business.
            </li>
            <li>
              You can request deletion of your data by sending an email to travis[at]freetrail.com.
              We will respond to confirm that your data has been deleted.
            </li>
          </ul>
          <p>
            <span className="font-medium">2. Security</span>
          </p>
          <ul>
            <li>
              We utilize technical, administrative and physical safeguards to help protect against
              the unauthorized access or the use or disclosure of your information. We are committed
              to providing you a safe browsing experience. However, no security system is 100%
              secure; we cannot guarantee information will not be used or disclosed by unauthorized
              persons and there is always a risk that information stored on our servers or
              transmitted by you online could be compromised.
            </li>
            <li>
              Any passwords used for this site are for individual use only. You will be responsible
              for the security of your password (if any) and you agree to accept responsibility for
              all activities that occur under your account or password. We have the right to monitor
              your password and, at our discretion, require you to change it. If you use a password
              that we consider insecure, we will have the right to require the password to be
              changed and/or terminate your account. You are prohibited from using any services or
              facilities provided in connection with this site to compromise security or tamper with
              system resources and/or accounts. The use or distribution of tools designed for
              compromising security (e.g., password guessing programs, cracking tools or network
              probing tools) is strictly prohibited. If you become involved in any violation of
              system security, we have the right to release your details to system administrators at
              other sites in order to assist them in resolving security incidents. We reserve the
              right to investigate suspected violations of this Agreement, and we reserve the right
              to fully cooperate with any law enforcement authorities or court order requesting or
              directing us to disclose the identity of anyone posting any Content that is believed
              to violate this Agreement. By accepting this agreement you waive, and agree to hold us
              and our subsidiaries, affiliates, licensors, service providers, content providers,
              employees, agents, officers, and directors harmless from any claims resulting from any
              action taken by us during or as a result of our investigations and/or from any actions
              taken as a consequence of investigations by us or others, including law enforcement
              authorities.
            </li>
          </ul>
          <p>
            <span className="font-medium">3. Third Party Websites</span>
          </p>
          <p>
            This site contains links and information pertaining to third parties and third-party
            websites. Those third parties may have their own privacy policy. We are not responsible
            for your use of third party websites or their privacy policies.
          </p>
        </div>
      </PaddedContent>
    </MainContent>
    <Footer />
  </>
);

export default Privacy;
