import React from 'react';
import { DEFAULT_PROFILE_IMAGE_URL } from '../lib/constants';

const UserAvatar: React.FC<{ avatarURL: string }> = ({ avatarURL }) => (
  <object data={avatarURL} type="image/png" className="mr-3 inline h-6 w-6 rounded-full">
    <img
      src={DEFAULT_PROFILE_IMAGE_URL}
      className="inline h-6 w-6 rounded-full"
      referrerPolicy="no-referrer"
    />
  </object>
);

export default UserAvatar;
