import React, { useState, useEffect, PropsWithChildren } from 'react';

interface LoadingProps {
  margin?: boolean;
}

const Loading = ({ margin }: PropsWithChildren<LoadingProps>) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 1) % 100);
    }, 10);

    return () => clearInterval(intervalId);
  }, [progress]);

  const progressWidth = { width: `${progress}%` };
  return (
    <div className={`relative h-2 w-full bg-gray-100 ${margin ? 'mx-4' : ''}`}>
      <div className="bg-gray-300 h-2" style={progressWidth} />
    </div>
  );
};

export default Loading;
