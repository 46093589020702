import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import StatusBadge from '../components/status-badge';
import UserContext from '../context/user-context';
import SectionDescription from '../components/section-description';
import Footer from '../components/footer';
import SeriesBadges from '../components/series-badges';
import Loading from '../components/loading';
import { EventData } from '../lib/model';
import MainContent, { PaddedContent } from '../components/main-content';
import { format, parseISO } from 'date-fns';
import NationalityFlag from '../components/nationality-flag';

const Events = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<EventData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = await api(user.accessToken).getEvents();
      setEvents(eventsData);
      setIsLoading(false);
    };

    fetchEvents();
  }, [user.isAuthenticated]);

  return (
    <>
      <PageHeader title="Events" subTitle="Browse recent and upcoming events" />
      <MainContent>
        <PaddedContent>
          <SectionDescription>
            <span className="text-gray-600">
              Are we missing an event?{' '}
              <a
                className="hyperlink underline"
                href="https://freetrail.com/contact/"
                target="_blank"
              >
                Contact us
              </a>
              .
            </span>
          </SectionDescription>
        </PaddedContent>
        <div className="overflow-x-scroll sm:overflow-hidden ">
          <table className="ft-table">
            <thead>
              <tr>
                <th scope="col">Race Name</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Location</th>
                <th scope="col">Event Series</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6} className="whitespace-nowrap px-6 py-4">
                    <Loading />
                  </td>
                </tr>
              )}
              {!isLoading &&
                events.map((event) => (
                  <tr
                    key={event.uuid}
                    onClick={() => navigate(`/events/${event.uuid}`)}
                    className="hover:cursor-pointer hover:bg-gray-50"
                  >
                    <td className="whitespace-nowrap  px-6 py-4">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <Link to={`/events/${event.uuid}`}>
                            <img className="h-10 w-10 rounded-full" src={event.image_url} alt="" />
                          </Link>
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-normal text-gray-900">
                            <Link to={`/events/${event.uuid}`}>{event.name}</Link>
                          </div>
                          <div className="text-sm text-gray-500">{event.format}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap  px-6 py-4 text-left">
                      <StatusBadge status={event.status} />
                    </td>
                    <td className="whitespace-nowrap  px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {event.start_date
                          ? format(parseISO(event.start_date), 'LLL d, yyyy')
                          : event.dates}
                      </div>
                    </td>
                    <td className="whitespace-nowrap  px-6 py-4">
                      <div className="text-sm capitalize text-gray-900">
                        <span className="mr-2">
                          <NationalityFlag country={event.country} />
                        </span>
                        {event.location}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <SeriesBadges event={event} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default Events;
