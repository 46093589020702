import React, { useContext, useEffect, useState } from 'react';
import Footer from '../components/footer';
import MainContent, { PaddedContent } from '../components/main-content';
import PageHeader from '../components/page-header';
import DataTable from 'react-data-table-component';
import api from '../lib/api';
import UserContext from '../context/user-context';
import SeasonSelect, { selectStyles } from '../components/season-select';
import RankText from '../components/rank-text';
import Loading from '../components/loading';
import UserAvatar from '../components/user-avatar';
import Select from 'react-select';
import { LeaderboardUser, LeaderboardVariant } from '../lib/model';

const PER_PAGE = 50;

interface SeasonLeaderboardProps {
  eventId?: string;
  leagueId?: string;
  noDataComponent?: JSX.Element;
  year: number;
  variant?: LeaderboardVariant;
}

export const SeasonLeaderboard: React.FC<SeasonLeaderboardProps> = ({
  eventId,
  leagueId,
  noDataComponent,
  year,
  variant
}) => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [resetPagination, setResetPagination] = useState(false);
  const [data, setData] = useState<LeaderboardUser[]>([]);

  useEffect(() => {
    setResetPagination(true);
    setIsLoading(true);

    const fetchData = async () => {
      // Fetch leaderboard
      const leaderboard = await api(user.accessToken).getYearLeaderboard(
        year,
        leagueId,
        eventId,
        variant
      );
      setData(leaderboard);
      setResetPagination(false);
      setIsLoading(false);
    };

    fetchData();
  }, [year, leagueId, eventId, variant]);

  return (
    <>
      <DataTable
        className="data-table"
        columns={[
          {
            name: 'RANK',
            selector: (_row, index) => {
              return (page - 1) * PER_PAGE + index! + 1;
            },
            minWidth: '110px',
            cell: (_row, index) => <RankText rank={(page - 1) * PER_PAGE + index + 1} />
          },
          {
            name: 'NAME',
            grow: 2,
            cell: (row) => (
              <>
                <UserAvatar avatarURL={row.profile_image_url} />
                <span>{row.name}</span>
              </>
            ),
            minWidth: '175px'
          },
          {
            name: 'TOTAL SCORE',
            selector: (row) => row.total_score,
            format: (row) => row.total_score.toLocaleString(),
            sortable: true,
            minWidth: '150px'
          },
          {
            name: 'WOMEN’S SCORE',
            selector: (row) => row.female_score,
            format: (row) => row.female_score.toLocaleString(),
            sortable: true,
            minWidth: '150px'
          },
          {
            name: 'MEN’S SCORE',
            selector: (row) => row.male_score,
            format: (row) => row.male_score.toLocaleString(),
            sortable: true,
            minWidth: '150px'
          },
          {
            name: 'EVENTS PLAYED',
            selector: (row) => row.events_played,
            format: (row) => row.events_played.toLocaleString(),
            sortable: true,
            minWidth: '150px'
          },
          {
            name: 'PPE',
            selector: (row) => row.points_per_event,
            format: (row) => row.points_per_event.toLocaleString(),
            sortable: true
          },
          {
            name: 'AVG RANK',
            selector: (row) => row.average_rank,
            format: (row) => row.average_rank.toLocaleString(),
            sortable: true
          }
        ]}
        customStyles={{
          head: {
            style: { color: '#fff', fontWeight: 400 }
          },
          headRow: {
            style: { backgroundColor: '#111' }
          }
        }}
        data={data}
        defaultSortFieldId={3}
        defaultSortAsc={false}
        onChangePage={(page) => setPage(page)}
        progressPending={isLoading}
        progressComponent={<Loading margin />}
        noDataComponent={
          noDataComponent || (
            <span className="my-12 px-4 text-sm">
              No data available for this season and leaderboard.
            </span>
          )
        }
      />
    </>
  );
};

const VariantSelect = ({
  onChange,
  variant
}: {
  onChange: (value: LeaderboardVariant) => void;
  variant: LeaderboardVariant;
}) => {
  const variantOptions = [
    { value: 'overall', label: 'Overall' },
    { value: 'pick_five', label: 'Pick Five' },
    { value: 'pick_ten', label: 'Pick Ten' }
  ];

  return (
    <>
      <label className="mb-2 block text-sm font-medium">Leaderboard</label>
      <Select
        className="
          text-gray-7000
          mb-6
          block
          w-48
          text-sm
          font-normal"
        defaultValue={{ value: 'overall', label: 'Overall' }}
        value={{ value: variant, label: variantOptions.find((v) => v.value === variant)?.label }}
        options={variantOptions}
        onChange={(newValue) => onChange((newValue?.value as LeaderboardVariant) || 'overall')}
        styles={selectStyles}
      />
    </>
  );
};

const Leaderboards = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedVariant, setSelectedVariant] = useState<LeaderboardVariant>('overall');

  return (
    <>
      <PageHeader title="Leaderboards" subTitle="View the top fantasy players by season" />
      <MainContent>
        <PaddedContent>
          <div className="sm:flex sm:flex-row sm:gap-4">
            <div>
              <SeasonSelect onChange={({ value }) => setSelectedYear(parseInt(value))} />
            </div>
            <div>
              <VariantSelect onChange={setSelectedVariant} variant={selectedVariant} />
            </div>
          </div>
        </PaddedContent>
        <SeasonLeaderboard year={selectedYear} variant={selectedVariant} />
      </MainContent>
      <Footer />
    </>
  );
};

export default Leaderboards;
