import * as React from 'react';
import { PropsWithChildren } from 'react';
import { EventSponsor } from '../lib/model';

interface PageHeaderProps {
  title: any;
  subTitle?: any;
  sponsor?: EventSponsor;
}

const PageHeader = ({ title, subTitle, sponsor }: PropsWithChildren<PageHeaderProps>) => (
  <header className="border-b border-gray-300 bg-white shadow">
    <div className="mx-auto flex max-w-7xl flex-wrap justify-between px-4 py-6 sm:px-6 lg:px-8">
      <div className="flex-1">
        <h1 className="text-zinc-900 text-2xl font-semibold">{title}</h1>
        {subTitle && <h2 className="mt-2 text-base font-medium text-gray-500">{subTitle}</h2>}
      </div>
      {Boolean(sponsor) && sponsor === EventSponsor.KetoneIQ && (
        <>
          <div className="hidden md:block">
            <p className="mb-3 text-center text-sm italic text-gray-500">presented by</p>
            <a href="https://hvmn.com/FREETRAIL30" target="_blank">
              <img
                className="mx-auto w-40"
                src="https://cdn.shopify.com/s/files/1/1515/2714/files/KetoneIQ_logo.png"
              />
            </a>
          </div>
          <div className="mt-8 block w-full md:hidden">
            <p className="mb-2 text-xs font-medium italic text-gray-500">presented by</p>
            <a href="https://hvmn.com/FREETRAIL30" target="_blank">
              <img
                className="mr-4 inline-block w-24"
                src="https://cdn.shopify.com/s/files/1/1515/2714/files/KetoneIQ_logo.png"
              />
            </a>
          </div>
        </>
      )}
      {Boolean(sponsor) && sponsor === EventSponsor.HOKA && (
        <>
          <div className="hidden md:block">
            <p className="mb-3 text-center text-sm italic text-gray-500">presented by</p>
            <a href="https://www.hoka.com/en/us/" target="_blank">
              <img
                className="mx-auto w-36"
                src="https://d2gwq01zobnbgs.cloudfront.net/assets/sponsors/hoka.png"
              />
            </a>
          </div>
          <div className="mt-8 block w-full md:hidden">
            <p className="mb-4 text-xs italic text-gray-500">presented by</p>
            <a href="https://www.hoka.com/en/us/" target="_blank">
              <img
                className="mr-4 inline-block w-32"
                src="https://d2gwq01zobnbgs.cloudfront.net/assets/sponsors/hoka.png"
              />
            </a>
          </div>
        </>
      )}
    </div>
  </header>
);

export default PageHeader;
