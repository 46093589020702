import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import SectionDescription from '../components/section-description';
import Footer from '../components/footer';
import Loading from '../components/loading';
import DataTable from 'react-data-table-component';
import NationalityFlag from '../components/nationality-flag';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import SeasonSelect from '../components/season-select';
import MainContent, { PaddedContent } from '../components/main-content';
import { AthletesSubNav } from '../components/nav';

const FreeAgency = () => {
  const user = useContext(UserContext);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [freeAgents, setFreeAgents] = useState([]);
  const year = parseInt(params.year!!, 10);
  const navigate = useNavigate();

  useEffect(async () => {
    api(user.accessToken)
      .getFreeAgents(year)
      .then((response) => {
        response.json().then((body) => {
          setFreeAgents(body.data.free_agents);
          setIsLoading(false);
        });
      });
  }, [user.isAuthenticated, year]);

  return (
    <>
      <AthletesSubNav />
      <PageHeader
        title={`Free Agency (${year})`}
        subTitle={`Track trail running team moves in ${year}`}
      />
      <MainContent>
        <PaddedContent>
          <SeasonSelect
            onChange={({ value }) => {
              navigate(`/athletes/free-agency/${value}`);
            }}
            year={year}
          />
          <SectionDescription>Please note that this is not comprehensive.</SectionDescription>
        </PaddedContent>
        <DataTable
          className="data-table"
          columns={[
            {
              name: 'NAME',
              id: 1,
              sortable: true,
              selector: (row) => [row.athlete!!.first_name, row.athlete.last_name].join(' '),
              minWidth: '200px',
              cell: (row) => (
                <>
                  <img
                    src={row.athlete.profile_image_urls.thumb}
                    className="mr-4 inline h-10 w-10 rounded-full"
                    referrerPolicy="no-referrer"
                  />
                  <span>
                    <Link className="text-sm" to={`/athletes/${row.athlete.profile_slug}`}>
                      {row.athlete.first_name} {row.athlete.last_name}
                    </Link>
                  </span>
                </>
              )
            },
            {
              name: 'NATIONALITY',
              id: 2,
              sortable: true,
              hide: 'md',
              selector: (row) => row.athlete.country,
              cell: (row) => <NationalityFlag country={row.athlete.country} />
            },
            {
              name: 'INSTAGRAM',
              id: 3,
              sortable: true,
              minWidth: '150px',
              hide: 'md',
              selector: (row) => row.athlete.instagram_handle,
              cell: (row) =>
                Boolean(row.athlete.instagram_handle) ? (
                  <a
                    href={`https://instagram.com/${row.athlete.instagram_handle}`}
                    target="_blank"
                    className="hyperlink text-sm underline"
                  >
                    @{row.athlete.instagram_handle}
                  </a>
                ) : (
                  <span></span>
                )
            },
            {
              name: 'FROM',
              id: 4,
              selector: (row) => row.from_team?.name || 'z',
              sortable: true,
              minWidth: '150px',
              cell: (row) =>
                Boolean(row.from_team) ? (
                  <>
                    <img
                      src={row.from_team.logo_urls.thumb}
                      className="mr-3 inline h-8 w-8 rounded-full"
                      referrerPolicy="no-referrer"
                    />
                    <span>
                      <Link className="text-sm" to={`/teams/${row.from_team.profile_slug}`}>
                        {row.from_team.name}
                      </Link>
                    </span>
                  </>
                ) : (
                  <span></span>
                )
            },
            {
              name: 'TO',
              id: 5,
              selector: (row) => row.to_team?.name || 'z',
              sortable: true,
              minWidth: '150px',
              cell: (row) =>
                Boolean(row.to_team) ? (
                  <>
                    <img
                      src={row.to_team.logo_urls.thumb}
                      className="mr-3 inline h-8 w-8 rounded-full"
                      referrerPolicy="no-referrer"
                    />
                    <span>
                      <Link className="text-sm" to={`/teams/${row.to_team.profile_slug}`}>
                        {row.to_team.name}
                      </Link>
                    </span>
                  </>
                ) : (
                  <span>
                    <DotsHorizontalIcon className="h-5 w-5 text-gray-500" />
                  </span>
                )
            }
          ]}
          customStyles={{
            head: {
              style: { color: '#fff', fontWeight: 400 }
            },
            headRow: {
              style: { backgroundColor: '#111' }
            },
            rows: {
              style: {
                minHeight: 60
              }
            }
          }}
          data={freeAgents}
          defaultSortFieldId={1}
          responsive
          noDataComponent={<p className="text-sm">No free agents for year {year}</p>}
          progressPending={isLoading}
          progressComponent={<Loading />}
        />
      </MainContent>
      <Footer />
    </>
  );
};

export default FreeAgency;
