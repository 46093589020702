import React from 'react';
import { EventStatus } from '../lib/model';

export const STATUS_TEXT = {
  [EventStatus.Open]: 'Make Your Picks',
  [EventStatus.Closed]: 'Picks Locked In',
  [EventStatus.ResultsPosted]: 'Results Posted',
  [EventStatus.Selected]: 'On The Horizon'
};

const StatusBadge = ({ status }: { status: string }) => {
  const baseClasses = 'px-3 py-1 inline-flex text-xs leading-5 font-medium';
  const text = STATUS_TEXT[status as EventStatus];

  return (
    <>
      {status === EventStatus.Open && (
        <span className={`${baseClasses} bg-green-100 text-green-800`}>{text}</span>
      )}
      {status === EventStatus.Closed && (
        <span className={`${baseClasses} bg-lazer-lemon-500 text-zinc-800`}>{text}</span>
      )}
      {status === EventStatus.ResultsPosted && (
        <span className={`${baseClasses} bg-blue-100 text-blue-700`}>{text}</span>
      )}
      {status === EventStatus.Selected && (
        <span className={`${baseClasses} bg-orange-100 text-orange-700`}>{text}</span>
      )}
    </>
  );
};

export default StatusBadge;
