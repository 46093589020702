import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="text-center lg:text-left bg-gray-100 text-gray-600">
    <div className="text-center bg-gray-500 py-10 font-mono uppercase">
      <p className="flex items-center justify-center text-white">
        <Link to="/faqs" className="mr-12">
          FAQs
        </Link>
        <Link to="/privacy" className="">
          Privacy Policy
        </Link>
      </p>
    </div>
    <div className="text-center p-8 bg-black text-white text-sm">
      <span>© {new Date().getFullYear()} </span>
      <a href="https://freetrail.com">
        <img src="/images/ft-logo.svg" className="w-28 ml-2 inline-block invert" />
      </a>
    </div>
  </footer>
);

export default Footer;
