import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

const Tooltip = ({ children }) => (
  <span className="has-tooltip">
    <InformationCircleIcon
      className="ml-1 inline-block h-4 w-4 flex-shrink-0 align-top text-black/80"
      aria-hidden="true"
    />
    <span className="tooltip border border-black p-3 max-w-sm text-xs leading-5 font-normal">
      {children}
    </span>
  </span>
);

export default Tooltip;
