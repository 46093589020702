import React from 'react';
import { DEFAULT_PROFILE_IMAGE_URL } from '../lib/constants';
import { Entrant } from '../lib/model';

interface EntrantNameProps {
  entrant: Entrant;
  onClick: () => void;
  showImage?: boolean;
  largeImage?: boolean;
  includePerformance?: boolean;
}

const EntrantName = ({
  entrant,
  onClick,
  showImage = true,
  largeImage = false,
  includePerformance = false
}: EntrantNameProps) => (
  <>
    {showImage &&
      (entrant.athlete?.profile_image_urls?.thumb &&
      entrant.athlete.profile_image_urls.thumb !== DEFAULT_PROFILE_IMAGE_URL ? (
        <img
          src={entrant.athlete?.profile_image_urls?.thumb}
          className={`mr-3 inline-flex items-center justify-center rounded-full ${
            largeImage ? 'h-10 w-10' : 'h-8 w-8'
          }`}
        />
      ) : (
        <div
          className={`mr-3 inline-flex items-center justify-center rounded-full ${
            largeImage ? 'h-10 w-10' : 'h-8 w-8'
          } bg-gray-400`}
        >
          <span className="font-mono text-xs text-white">
            {[entrant.first_name, entrant.last_name]
              .filter((n) => n)
              .map((n) => n[0].toUpperCase())
              .join('')}
          </span>
        </div>
      ))}
    <button
      className={`mr-1 text-left ${entrant.started_race ? '' : 'line-through'}`}
      onClick={onClick}
    >
      <span className="underline">
        {entrant.first_name} {entrant.last_name}
      </span>
      {includePerformance && Boolean(entrant.result) && (
        <span className="mb-1 mt-2 block text-left text-xs text-gray-700">
          {entrant.result?.race?.name}
        </span>
      )}
    </button>
    {entrant.result_count != undefined && (
      <a
        target="_blank"
        href={`https://ultrasignup.com/results_participant.aspx?fname=${entrant.first_name}&lname=${entrant.last_name}`}
        className="hyperlink"
      >
        ({entrant.result_count})
      </a>
    )}
  </>
);

export default EntrantName;
