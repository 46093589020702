import React from 'react';
import Footer from '../components/footer';
import MainContent, { PaddedContent } from '../components/main-content';

export enum ContentKey {
  UtmbPrizes = 1,
  UtmbPreview = 2
}

const contentMap: Map<ContentKey, { title: string; subTitle: string | any; body: any }> = new Map([
  [
    ContentKey.UtmbPrizes,
    {
      title: 'UTMB Week Fantasy Trail Prize Packages',
      subTitle: 'August 22, 2022',
      body: (
        <>
          <p>
            The biggest week of the trail running year is officially here! Chamonix, France will
            again play host to the nineteenth running of the Ultra Trail du Mont Blanc. The
            week-long event draws more than 10,000 total runners including virtually all of the
            world’s best athletes who will compete across a variety of distances. Each race as a
            stand alone event would be one of the most competitive of the year, so having them all
            take place over a few days provides intense and dramatic entertainment for trail fans
            worldwide.
          </p>
          <p>
            The depth and density of professional talent in the mix this year is perhaps the most
            impressive in the event’s history. As such, Freetrail is proud to announce the following
            prize packages for our Fantasy Trail competition taking place throughout the week.
          </p>
          <h2>OCC - Thursday, August 25th 8:15am CET</h2>
          <ul>
            <li>
              <a href="https://www.runspeedland.com/">Speedland SL:HSV Trail Shoes</a>
            </li>
            <li>
              <a href="https://www.insidetracker.com/">InsideTracker Ultimate Blood Test</a>
            </li>
            <li>
              <a href="https://gognarly.com/">$50 Gnarly Nutrition Gift Certificate</a>
            </li>
            <li>
              <a href="https://athleticbrewing.com/">$50 Athletic Brewing Gift Certificate</a>
            </li>
            <li>
              <a href="https://www.julbo.com/en_us/sunglasses/ultimate#216=258&318=23852">
                Julbo ULTIMATE Sunglasses
              </a>
            </li>
            <li>
              <a href="https://www.petzl.com/US/en/Sport/Headlamps/ACTIK-CORE">
                Petzl ACTIK CORE Headlamp
              </a>
            </li>
            <li>Freetrail swag</li>
          </ul>
          <h2>CCC - Friday, August 26th 9am CET</h2>
          <ul>
            <li>
              <a href="https://www.runspeedland.com/">Speedland SL:HSV Trail Shoes</a>
            </li>
            <li>
              <a href="https://www.insidetracker.com/">InsideTracker Ultimate Blood Test</a>
            </li>
            <li>
              <a href="https://gognarly.com/">$50 Gnarly Nutrition Gift Certificate</a>
            </li>
            <li>
              <a href="https://athleticbrewing.com/">$50 Athletic Brewing Gift Certificate</a>
            </li>
            <li>
              <a href="https://www.julbo.com/en_us/sunglasses/ultimate#216=258&318=23852">
                Julbo ULTIMATE Sunglasses
              </a>
            </li>
            <li>
              <a href="https://www.petzl.com/US/en/Sport/Headlamps/IKO-CORE">
                Petzl IKO CORE Headlamp
              </a>
            </li>
            <li>Freetrail swag</li>
          </ul>
          <h2>UTMB - Friday, August 26th 6pm CET</h2>
          <ul>
            <li>
              <a href="https://www.runspeedland.com/">Speedland SL:HSV Trail Shoes</a>
            </li>
            <li>
              <a href="https://www.insidetracker.com/">InsideTracker Ultimate Blood Test</a>
            </li>
            <li>
              <a href="https://gognarly.com/">$50 Gnarly Nutrition Gift Certificate</a>
            </li>
            <li>
              <a href="https://athleticbrewing.com/">$50 Athletic Brewing Gift Certificate</a>
            </li>
            <li>
              <a href="https://www.julbo.com/en_us/sunglasses/ultimate#216=258&318=23852">
                Julbo ULTIMATE Sunglasses
              </a>
            </li>
            <li>
              <a href="https://www.petzl.com/US/en/Sport/Headlamps/SWIFT-RL">
                Petzl SWIFT RL Headlamp
              </a>
            </li>
            <li>Freetrail swag</li>
          </ul>
          <h2>Aggregate Winner Across Three Events</h2>
          <ul>
            <li>
              <a href="https://www.runspeedland.com/">Speedland SL:HSV Trail Shoes</a>
            </li>
            <li>
              <a href="https://www.insidetracker.com/">InsideTracker Ultimate Blood Test</a>
            </li>
            <li>
              <a href="https://gognarly.com/">$50 Gnarly Nutrition Gift Certificate</a>
            </li>
            <li>
              <a href="https://athleticbrewing.com/">$50 Athletic Brewing Gift Certificate</a>
            </li>
            <li>
              <a href="https://www.julbo.com/en_us/sunglasses/ultimate#216=258&318=23852">
                Julbo ULTIMATE Sunglasses
              </a>
            </li>
            <li>
              <a href="https://www.petzl.com/US/en/Sport/Headlamps/NAO-PLUS">Petzl NAO+ Headlamp</a>
            </li>
            <li>Freetrail swag</li>
          </ul>
          <p>
            All these prizes add up to more than $6,000 in prize value! A big thank you to all the
            brands who wanted to support this fun engagement around what are sure to be very special
            races.
          </p>
          <p>
            <span className="font-semibold">**Important Note #1**</span> Pick ten deep across all
            three races rather than our typical five.
          </p>
          <p>
            <span className="font-semibold">**Important Note #2**</span> Freetrail will make every
            effort to send prizes abroad in the case there are non-American winners, however we
            cannot guarantee it.
          </p>
          <p>
            <a
              className="underline"
              href="https://fantasy.freetrail.com/series/881d26e5-e866-402e-88cf-55f5850d37fa"
            >
              Go get your picks in!
            </a>
          </p>
        </>
      )
    }
  ],
  [
    ContentKey.UtmbPreview,
    {
      title: 'UTMB 2022 Preview – Four things to watch for in Chamonix',
      subTitle: (
        <>
          August 23, 2022 - By: Liam Tryon (
          <a
            href="https://twitter.com/aidstationfireb"
            target="_blank"
            className="hyperlink font-medium"
          >
            @aidstationfireball
          </a>
          )
        </>
      ),
      body: (
        <>
          <p>
            With the end of August here, the trail and ultrarunning world has shifted its focus onto
            the Ultra Trail du Mont Blanc (UTMB), a 106.5-mile race through the Alps, featuring
            nearly 33,000 feet of elevation gain. The course begins in Chamonix, France, and
            encircles the highest mountain in the Alps, Mont Blanc, passing through major
            checkpoints in Courmayeur, Italy and Champex-Lac, Switzerland, en route to a finish back
            in Chamonix.
          </p>
          <p>
            This race has become the de facto world championship of ultra-distance trail running in
            recent years, with tens of thousands of international race participants descending upon
            the French town to participate in this race and its affiliated shorter ultra-distance
            and sub-marathon distance events. In 2022, the headlining UTMB race features perhaps the
            deepest men’s and women’s fields ever assembled for the event. The aim of this article
            is to provide a few items that I, as both an avid follower of the sport and frequent
            participant in Freetrail Fantasy speculation, have been looking out for in the build-up
            to the race, and will be watching for as the race unfolds.
          </p>
          <img
            className="mt-8"
            src="https://d2gwq01zobnbgs.cloudfront.net/assets/content/mont-blanc.jpeg"
          />
          <p className="mb-8 text-xs">Photo by Ryan Thrower</p>
          <h2>
            Pre-Race Preparation – Who is riding a hot 2022 race season and who is racing the
            Western States-UTMB "double"
          </h2>
          <p>
            The 2022 race features an exhaustive list of elite talent, which is tough to deconstruct
            given how many of the runners have had international success at in the sport. An easy
            approach to getting a feel for who has had success earlier this race season may be a way
            to get a sense for who might be due for a podium or top ten finish at UTMB. Let’s take a
            quick look at who has been "hot" in 2022 racing so far:
          </p>
          <h2>Women’s Field</h2>
          <ul>
            <li>
              <strong>Ragna Debats</strong> - Despite a DNF at this year’s Lavaredo Ultra Trail by
              UTMB 121k race, the Dutch athlete has logged a first-place finish at each of the
              Transgrancanaria Classic 126k, Istria 100 by UTMB 168km and Montreux Trail Festival
              MXALPS 72k races this year.
            </li>
            <li>
              <strong>Katie Schide</strong> - With top 10 finishes at both previous UTMB races, the
              American athlete has started hot this year with victories at both the MaXi-Race
              Marathon 40k and Val d’Aran by UTMB 105k race.
            </li>
            <li>
              <strong>Audrey Tanguay</strong> - The French athlete kicked off her season early with
              an impressive second place finish at the Madeira Island Ultra-Trail 115k and a win at
              the Trail du Ventoux 2022 46k race.
            </li>
            <li>
              <strong>Manon Bohard</strong> - The 2021 TDS champion has started her race season with
              victories at the Innsbruck Alpine Trailrun Festival K85 79km race, as well as the
              Trail Verbier St Bernard by UTMB X-Traversée 76k race.
            </li>
            <li>
              <strong>Marianne Hogan</strong> - The Canadian athlete started off her season with a
              win at the Bandera Endurance Trail Run 100k, earning her a Golden Ticket to the
              Western States Endurance Run, where she finished in third place.
            </li>
            <li>
              <strong>Mimmi Kotka</strong> - A podium finisher at last year’s UTMB, the Swede has
              started off the year with a second-place finish at the MaXi-Race Marathon 40k (second
              to <strong>Katie Schide</strong>) and captured the victory at the Lavaredo Ultra Trail
              by UTMB 121k race.
            </li>
            <li>
              <strong>Katarzyna Solinska</strong> - While starting the year placing well at some
              more regional Polish ultradistance races, she took second behind{' '}
              <strong>Mimmi Kotka</strong> at the Lavaredo Ultra Trail by UTMB 121k race.
            </li>
            <li>
              <strong>Emily Vaudan</strong> - Although she was unable to complete the UTMB loop last
              year, the Swiss athlete took the win at the Trail Verbier St Bernard by UTMB 2022
              X-Alpine 140k race in early July.
            </li>
            <li>
              <strong>Kimino Miyazaki</strong> - The Japanese athlete scored a big victory at this
              year’s Ultra-Trail Mt Fuji 165k.
            </li>
          </ul>
          <h2>Men’s Field</h2>
          <ul>
            <li>
              <strong>Kilian Jornet</strong> - The 3-time UTMB champion kicked off his season with
              wins and course records at Zegama-Aizkorri 42k and the Hardrock 100 Endurance Run, and
              just under two weeks ago, placed fifth at Sierre-Zinal 31k.
            </li>
            <li>
              <strong>Jim Walmsley</strong> - With only one major race on the calendar this year,
              the American athlete took the overall win at the Madeira Island Ultra-Trail 115k.
            </li>
            <li>
              <strong>Hannes Namberger</strong> - The German athlete has taken overall wins at both
              the Penyagolosa Trails 106k and Lavaredo Ultra Trail 121k so far this year.
            </li>
            <li>
              <strong>Thibaut Garrivier</strong> - The French 2021 CCC winner claimed victory at
              both the Grand Raid 73 and Val Tho Summit Games Trail Pursuit 42k earlier this year,
              while finishing second to <strong>Jim Walmsley</strong> at the Madeira Island
              Ultra-Trail 115k.
            </li>
            <li>
              <strong>Pablo Villa</strong> - The Spaniard kicked off 2022 with an overall win at the
              Transgrancanaria Classic 126k.
            </li>
            <li>
              <strong>Pau Capell</strong> - The 2019 UTMB champion has raced extensively this year,
              recording overall wins at Patagonia Run 163k and many other smaller field races, while
              grabbing second at the Transgrancanaria Classic 126k.
            </li>
            <li>
              <strong>Germain Grangier</strong> - Finishing fifth overall at UTMB last year, the
              French athlete has claimed victories at the Skyrace des Matheysins 41k, the Swiss
              Canyon Trail K81 83k and Val D’Aran by UTMB 160km this year.
            </li>
            <li>
              <strong>Zach Miller</strong> - Former 2015 CCC winner and two-time UTMB top ten
              finisher, the American won the Trail 100 Andorra by UTMB 105k earlier this summer.
            </li>
            <li>
              <strong>Robert Hajnal</strong> - The Romanian athlete took the overall win at the
              Istria 100 by UTMB 168k earlier this year, while also grabbing fifth at the
              Transgrancanaria Classic 126k and seventh at Lavaredo Ultra Trail 121k.
            </li>
            <li>
              <strong>Benoit Girondel</strong> - Finishing second at last year’s TDS, the French
              athlete took the win at the Swiss Canyon Trail K111 115k.
            </li>
          </ul>
          <p>
            Numerous athletes are also undertaking the infamous Western States (WS)-UTMB “double,”
            where runners aim to complete both races in the same calendar year. Finishing in the top
            ten at both races in the same year has been met with limited success in previous years,
            with historically only a handful of athletes ever able to this feat, most recently by
            Zimbabwe’s <strong>Emily Hawgood</strong> in 2021 and the United States’{' '}
            <strong>Seth Swanson</strong> in 2015.
          </p>
          <p>
            This year, within the men’s elite field at UTMB, <strong>Tyler Green</strong> (4th at
            WS) looks to secure a top ten finish at both races, while runners{' '}
            <strong>Tim Tollefson</strong> and <strong>Rod Farvard</strong> (21st and 41st at WS)
            are looking to complete the WS-UTMB double. In the women’s elite field,{' '}
            <strong>Marianne Hogan</strong> (3rd at WS) and <strong>Emily Hawgood</strong> (5th at
            WS) look to grab a top ten spot in both Auburn and Chamonix, whereas Spain’s{' '}
            <strong>Aroa Sio Seijo</strong> and <strong>Idaho’s</strong> Brittany Peterson (12th and
            16th at WS, respectively) will aim to successful execute both races.
          </p>
          <p>
            Numerous other “non-elite” athletes are looking to successfully navigate both races,
            including <strong>Stephen Turner</strong>, <strong>Kostya Penkov</strong>,{' '}
            <strong>Andrew Smith</strong>, <strong>David Stuart</strong> of Great Britain,{' '}
            <strong>Zoran Kurdija</strong> of Croatia, <strong>Davide Ferrara</strong> of Italy,{' '}
            <strong>Michael Scogings</strong> of Peru, <strong>Phei Sunn Sim</strong> of Singapore,{' '}
            <strong>Ivan Dimitrov</strong> of Bulgaria, <strong>Dawson Mossman</strong> of Canada,{' '}
            <strong>Andrew Messina</strong> of New Jersey and <strong>Christian Sorensen</strong> of
            Utah. Best of luck to these runners in completing the Mont Blanc circumnavigation and
            completing the elusive double.
          </p>
          <h2>Race Strategy: Starting too fast portends an early race exit</h2>
          <p>
            It’s a time-honoured tradition to have hundreds of elite athletes depart Chamonix in an
            apparent all-out sprint, seemingly in a race to the top of the first climb up Le
            Délevret. Without fail, pushing to the front of the field too early on is consistently
            associated with an ultimate drop from the race, particularly in the men’s elite fields.
            As highlighted in the table below (<strong>Table 1</strong>), historically, of the top
            ten runners entering the aid stations at <strong>Les Contamines</strong> (31.2km) and{' '}
            <strong>Les Chapieux</strong> (50.1km), more than half of these runners will eventually
            drop from the race (showing only data since 2015, although this trend extends beyond
            that). Interestingly, the women’s field seems to be slightly more resistant with less
            eventual drops from the race despite fast starts to Les Contamines and Les Chapiuex.
            Keep this in mind when the lead athletes come through at this stage – there’s a good
            chance you may not see them at the finish line!
          </p>
          <p className="mt-8 text-sm">
            <strong>
              Table 1: Number of top ten runners with the fastest splits at Les Contamines (31.2km)
              and Les Chapieux (50.1km) aid stations that eventually dropped from the UTMB (170km)
              race.
            </strong>
          </p>
          <div className="overflow-x-scroll sm:overflow-hidden">
            <table className="table table-auto divide-y">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan="2" className="bg-black text-white">
                    Top 10 Fastest Splits at Les Contamines (31.2km)
                  </th>
                  <th colSpan="2" className="bg-black text-white">
                    Top 10 Fastest Splits to Les Chapieux (50.1km)
                  </th>
                </tr>
                <tr className="bg-gray-400">
                  <th>Year</th>
                  <th>Men’s Field</th>
                  <th>Women’s Field</th>
                  <th>Men’s Field</th>
                  <th>Women’s Field</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>2015</p>
                  </td>
                  <td>
                    <p>6 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>5 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>2016</p>
                  </td>
                  <td>
                    <p>6 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>3 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>6 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>3 of 10 runners dropped</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>2017</p>
                  </td>
                  <td>
                    <p>2 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>0 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>5 of 10 runners dropped</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>2018</p>
                  </td>
                  <td>
                    <p>8 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>7 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>2019</p>
                  </td>
                  <td>
                    <p>5 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>5 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>6 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>2021</p>
                  </td>
                  <td>
                    <p>7 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>5 of 10 runners dropped</p>
                  </td>
                  <td>
                    <p>4 of 10 runners dropped</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2>Podium and Top 10 Expectations: Anticipate Continued French Dominance</h2>
          <p>
            With Freetrail Fantasy picks and podium postulation in full strength this week, most
            would do well to be reminded of the dominance France has had in the race over the years.
            In the below table (<strong>Table 2</strong>), I’ve aimed to underscore how well France
            has done historically compared to the three next countries, in terms of placing its
            athletes in the top ten gender places. While I’ve only included the past ten UTMB races
            here, the trend of podium supremacy continues back to the race’s inception in 2003.
            Whether this is simply due to the total numbers of participants from France that
            register (with nearly 2800 participants registered for this year’s race, nearly 1000 of
            which are representing France), specific course knowledge, terrain familiarity or any
            other number of factors, the bottom line remains the same – always bet on the French to
            place highly.
          </p>
          <p className="mt-8 text-sm">
            <strong>
              Table 2: Number of total runners from that have placed in the top ten (combined from
              male and female gender places) at the UTMB (170km) races from 2011-present.
            </strong>
          </p>
          <div className="overflow-x-scroll sm:overflow-hidden">
            <table className="table table-auto divide-y">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan="10" className="bg-black text-white">
                    Race Year
                  </th>
                  <th></th>
                </tr>
                <tr className="bg-gray-400">
                  <th>Country</th>
                  <th>2011</th>
                  <th>2012</th>
                  <th>2013</th>
                  <th>2014</th>
                  <th>2015</th>
                  <th>2016</th>
                  <th>2017</th>
                  <th>2018</th>
                  <th>2019</th>
                  <th>2021</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>France</p>
                  </td>
                  <td>
                    <p>6</p>
                  </td>
                  <td>
                    <p>5</p>
                  </td>
                  <td>
                    <p>6</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>6</p>
                  </td>
                  <td>
                    <p>5</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>10</p>
                  </td>
                  <td>
                    <p>53</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Spain</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>6</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>33</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>United States</p>
                  </td>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>5</p>
                  </td>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>31</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>United Kingdom</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>-</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>-</p>
                  </td>
                  <td>
                    <p>12</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2>
            Potential Milestone Finishes: Longevity records, country bests and possible first-time
            UTMB loops
          </h2>
          <p>
            This year features <strong>Anke Drescher</strong>’s pursuit of her 18th consecutive
            finish of the UTMB race, having completed all eighteen previous editions of the race
            (excluding 2010, where the original race was cancelled due to inclement weather) – the
            only person to have a completion streak of this length. The 54-year-old German
            ultradistance runner has also completed numerous editions of the colossal Tor des Géants
            race in Italy, among several other European ultradistance mountain races. Frenchmen{' '}
            <strong>Bruno Poirier</strong> and <strong>Stéphane Galeazzi</strong> are pursuing their
            15th and 13th total UTMB finishes respectively, while Italy’s{' '}
            <strong>Marco Melchiorri</strong> tries to lock down his 12th total lap of Mont Blanc.
          </p>
          <p>
            From a United States perspective, Tennessee’s <strong>Joel Meredith</strong> is
            searching for his 5th UTMB finish, having previously completed the race in 2013, 2014,
            2017 and 2018. He’s second (alongside <strong>Rory Bosio</strong>) to the legendary{' '}
            <strong>Topher Gaylord</strong>, who has finished the race seven times previously.
            Colorado’s <strong>Jason Schlarb</strong> and Oregon’s <strong>David Laney</strong> are
            also both hunting for a fourth finish of the UTMB this year.
          </p>
          <p>
            This year’s race also opens the possibility for finishes from a few countries and
            regions that have never had a UTMB finisher before, including Albania’s{' '}
            <strong>Vladimir Kabetaj</strong>, Bahrain’s <strong>Fatema Husain</strong>, Kenya’s{' '}
            <strong>Victor Kamau Miringu</strong>, Kosovo’s <strong>Myrvete Zekaj Duli</strong> and{' '}
            <strong>Burim Leci</strong>, and Macao’s <strong>Alvaro Mourato</strong>. Let’s root
            these folks on and hope they can complete a first UTMB finish for themselves and their
            country!
          </p>
          <p>
            The race kicks off at 6:00pm local time in Chamonix (12pm Eastern time, 9am Pacific) on
            Friday August 26, and will be streaming live through the{' '}
            <a href="https://www.youtube.com/c/UltraTrailMontBlanc-UTMB">
              UTMB Official YouTube channel
            </a>
            . Hope to see you in the comments and live chat!
          </p>
        </>
      )
    }
  ]
]);

const Content = ({ contentKey }: { contentKey: ContentKey }) => {
  const content = contentMap.get(contentKey)!!;

  return (
    <>
      <header className="border-b border-gray-300 bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-8 text-center sm:px-6 lg:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">{content.title}</h1>
          {content.subTitle && (
            <h2 className="mt-3 font-mono text-base font-medium text-gray-500">
              {content.subTitle}
            </h2>
          )}
          <span className="mt-8 inline-block w-[40px] border-t border-black"></span>
        </div>
      </header>
      <MainContent>
        <PaddedContent>{content.body}</PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Content;
