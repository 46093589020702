import React from 'react';
import { withOrdinalSuffix } from '../lib/strings';
import { StarIcon, EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { FIELD_FEMALE, FIELD_MALE } from '../lib/model';
import { DEFAULT_PROFILE_IMAGE_URL } from '../lib/constants';
import CloseModalButton from './close-modal-button';

const ScorecardModal = ({ onClose, eventUser, event, communitySize }) => {
  return (
    <div
      id="scorecardModal"
      tabIndex={-1}
      aria-hidden="true"
      className="h-modal fixed inset-0 left-0 right-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-gray-900/60"
    >
      <div className="relative mx-auto mt-16 h-auto w-full max-w-lg p-4">
        <div className="relative bg-white shadow">
          <div className="pattern-profile flex items-start justify-between border border-gray-900 p-4 pb-3 shadow-sm">
            <div className="flex justify-between">
              <img
                src={event.image_url || DEFAULT_PROFILE_IMAGE_URL}
                className="mr-3 h-14 w-14 rounded-full border border-white bg-white"
              />
              <div>
                <h3 className="text-shadow-sm shadow-black/30 text-lg font-semibold text-white">
                  {event.name_and_format}
                </h3>
                <h4 className="text-shadow-sm shadow-black/30 my-1 text-sm font-normal  text-white">
                  Fantasy Scorecard
                </h4>
              </div>
            </div>
            <CloseModalButton onClick={onClose} />
          </div>
          <div className="space-y-6 border border-b-0 border-t-0 border-gray-900 py-4">
            <p className="px-4 text-sm leading-relaxed">
              You placed <span className="font-medium">{withOrdinalSuffix(eventUser.rank)}</span>{' '}
              out of {communitySize} players, with a total score of{' '}
              <span className="font-medium">{eventUser.total_score.toLocaleString()}</span>.
            </p>
            {[
              [
                eventUser.picks.filter((p) => p.entrant.field === FIELD_FEMALE),
                'Women’s',
                eventUser.female_score
              ],
              [
                eventUser.picks.filter((p) => p.entrant.field === FIELD_MALE),
                'Men’s',
                eventUser.male_score
              ]
            ]
              .filter(([picks]) => (picks || []).length)
              .map(([picks, label, score]) => {
                return (
                  <div key={label[0]}>
                    <h2 className="px-4 text-sm font-medium">{label} Picks</h2>
                    <div className="mt-2 overflow-x-scroll sm:overflow-hidden">
                      <table className="min-w-full table-auto">
                        <thead className="bg-zinc-900 w-10 whitespace-nowrap text-center text-xs font-normal uppercase text-white">
                          <tr>
                            <th className="py-2 font-normal">Name</th>
                            <th className="py-2 font-normal">Predicted</th>
                            <th className="py-2 font-normal">Actual</th>
                            <th className="py-2 font-normal">Points</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {picks.map((pick) => {
                            const { entrant } = pick;

                            return (
                              <tr key={pick.id} className="text-xs">
                                <td className="td-compact">
                                  {pick.rank === entrant.post_race_rank ? (
                                    <StarIcon
                                      className="text-purple-haze-500 mr-2 inline h-4 w-4 flex-shrink-0"
                                      aria-hidden="true"
                                    />
                                  ) : pick.score ? (
                                    <EmojiHappyIcon
                                      className="text-amber-400 mr-2 inline h-4 w-4 flex-shrink-0"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <EmojiSadIcon
                                      className="mr-2 inline h-4 w-4 flex-shrink-0 text-gray-500"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {entrant.first_name[0]}. {entrant.last_name}
                                </td>
                                <td className="text-center">{pick.rank}</td>
                                <td className="text-center">{entrant.post_race_rank || '-'}</td>
                                <td className="text-center">{pick.score}</td>
                              </tr>
                            );
                          })}
                          <tr className="text-xs">
                            <td className="td-compact text-right font-medium uppercase" colSpan={3}>
                              Score
                            </td>
                            <td className="td-compact text-center font-medium">
                              {score.toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
            <p className="px-4 text-xs leading-relaxed">
              <span className="mr-3">
                <StarIcon
                  className="text-purple-haze-500 inline h-4 w-4 flex-shrink-0"
                  aria-hidden="true"
                />{' '}
                Perfect
              </span>
              <span className="mr-3">
                <EmojiHappyIcon
                  className="text-amber-400 inline h-4 w-4 flex-shrink-0"
                  aria-hidden="true"
                />{' '}
                Points Awarded
              </span>
              <span className="mr-3">
                <EmojiSadIcon
                  className="inline h-4 w-4 flex-shrink-0 text-gray-500"
                  aria-hidden="true"
                />{' '}
                No Luck
              </span>
            </p>
            <p className="px-4 text-xs leading-relaxed">
              Check out our{' '}
              <Link className="hyperlink underline" to="/faqs">
                FAQs page
              </Link>{' '}
              to learn about our scoring methodology.
            </p>
          </div>
          <div className="pattern-profile flex items-center space-x-2 border border-gray-900 p-4">
            <button
              data-modal-toggle="scorecardModal"
              className="text-xs text-white underline focus:z-10"
              onClick={onClose}
            >
              Back to Event
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScorecardModal;
