import React from 'react';
import { format, parseISO } from 'date-fns';
import RankText from './rank-text';
import UserAvatar from './user-avatar';
import Loading from './loading';
import DataTable from 'react-data-table-component';

interface LeaderboardUser {
  is_current_user: boolean;
  rank: number;
  name: string;
  profile_image_url: string;
  total_score: number;
  female_score: number;
  male_score: number;
  picks_updated_at: string;
}

const Leaderboard = ({
  users,
  communitySize,
  showPicksFinalized = true
}: {
  users: LeaderboardUser[];
  communitySize: number;
  showPicksFinalized?: boolean;
}) => {
  return (
    <DataTable
      className="data-table mb-8 mt-2"
      fixedHeader
      columns={[
        {
          name: `RANK / ${communitySize.toLocaleString()}`,
          selector: (row) => row.rank,
          cell: (row) => (
            <span className="text-sm">
              <RankText rank={row.rank} />
            </span>
          ),
          minWidth: '110px'
        },
        {
          name: 'NAME',
          grow: 2,
          cell: (row) => (
            <>
              <UserAvatar avatarURL={row.profile_image_url} />
              <span className="mr-1">{row.name}</span>
              {row.is_current_user && <span className="italic text-gray-600">(it’s you!)</span>}
            </>
          ),
          minWidth: '175px'
        },
        {
          name: 'TOTAL SCORE',
          grow: 2,
          selector: (row) => row.total_score,
          format: (row) => row.total_score.toLocaleString(),
          sortable: true,
          minWidth: '150px'
        },
        {
          name: 'WOMEN’S SCORE',
          grow: 2,
          selector: (row) => row.female_score,
          format: (row) => row.female_score.toLocaleString(),
          sortable: true,
          minWidth: '150px'
        },
        {
          name: 'MEN’S SCORE',
          grow: 2,
          selector: (row) => row.male_score,
          format: (row) => row.male_score.toLocaleString(),
          sortable: true,
          minWidth: '150px'
        },
        {
          name: 'PICKS FINALIZED',
          selector: (row) => row.picks_updated_at,
          format: (row) => format(parseISO(row.picks_updated_at), 'LLL d, yyyy'),
          sortable: true,
          omit: !showPicksFinalized,
          minWidth: '150px'
        }
      ]}
      customStyles={{
        head: {
          style: { color: '#fff', fontWeight: 400 }
        },
        headRow: {
          style: { backgroundColor: '#111' }
        },
        rows: {
          style: {
            minHeight: 50,
            border: '1px solid #ddd',
            borderTop: 0
          }
        }
      }}
      data={users}
      defaultSortFieldId={2}
      defaultSortAsc={false}
      progressPending={communitySize === 0}
      progressComponent={<Loading margin />}
      noDataComponent={<span className="my-12 px-4 text-sm">...</span>}
      conditionalRowStyles={[
        {
          when: (row) => row.is_current_user,
          style: {
            backgroundColor: 'rgb(226, 232, 240)',
            border: '1px solid #222'
          }
        }
      ]}
    />
  );
};

export default Leaderboard;
