import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import SectionDescription from '../components/section-description';
import Footer from '../components/footer';
import Loading from '../components/loading';
import DataTable from 'react-data-table-component';
import NationalityFlag from '../components/nationality-flag';
import MainContent, { PaddedContent } from '../components/main-content';
import { Athlete, HeadToHeadData } from '../lib/model';
import AthleteSelect from '../components/athlete-select';
import { AthletesSubNav } from '../components/nav';

interface AthleteCardProps {
  athlete: Athlete;
}

const AthleteCard = ({ athlete }: AthleteCardProps) => {
  return (
    <div className="">
      <Link
        to={`/athletes/${athlete.profile_slug}`}
        className="mb-2 block text-center text-xl font-medium uppercase"
      >
        <span>
          {athlete.first_name} {athlete.last_name}
        </span>
      </Link>
      <p className="mb-4 text-center text-sm">
        <NationalityFlag country={athlete.country} showCountryName />
      </p>
      <div className="mx-auto w-64">
        <img src={athlete.profile_image_urls.standard} className="mx-auto mb-3 w-48 rounded" />
        <p className="text-center text-xs italic">
          {Boolean(athlete.teams.length) && (
            <span>
              <img
                src={athlete.teams[0].logo_urls.thumb}
                className="mr-2 inline h-6 w-6 rounded-full"
              />
              {athlete.teams[0].name}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

interface StatsComparisonProps {
  firstStats: HeadToHeadData;
  secondStats: HeadToHeadData;
}

const StatsComparison = ({ firstStats, secondStats }: StatsComparisonProps) => {
  const winClass = 'bg-green-200';
  const loseClass = 'bg-red-200';
  const tieClass = 'bg-white';

  const getClasses = (first: number, second: number) => {
    let classes = [tieClass, tieClass];
    if (first > second) {
      classes[0] = winClass;
      classes[1] = loseClass;
    } else if (first < second) {
      classes[0] = loseClass;
      classes[1] = winClass;
    }
    return classes;
  };

  const recordClasses = getClasses(firstStats.record.wins, secondStats.record.wins);
  const winClasses = getClasses(firstStats.wins, secondStats.wins);
  const podiumClasses = getClasses(firstStats.podiums, secondStats.podiums);
  const topTenClasses = getClasses(firstStats.top_tens, secondStats.top_tens);
  const dnfClasses = getClasses(-firstStats.dnfs, -secondStats.dnfs);

  return (
    <>
      <div className="flex flex-col text-center font-mono text-lg font-medium uppercase">
        <div className={`border border-gray-800 py-2 ${recordClasses[0]}`}>
          {firstStats.record.wins} - {firstStats.record.losses} - {firstStats.record.ties}
        </div>
        <div className={`border border-t-0 border-gray-800 py-2 ${winClasses[0]}`}>
          {firstStats.wins}
        </div>
        <div className={`border border-t-0 border-gray-800 py-2 ${podiumClasses[0]}`}>
          {firstStats.podiums}
        </div>
        <div className={`border border-t-0 border-gray-800 py-2 ${topTenClasses[0]}`}>
          {firstStats.top_tens}
        </div>
        <div className={`border border-t-0 border-gray-800 py-2 ${dnfClasses[0]}`}>
          {firstStats.dnfs}
        </div>
      </div>
      <div className=" flex flex-col text-center font-mono text-lg font-medium uppercase">
        <div className="border border-l-0 border-gray-800 bg-gray-200 py-2">Record</div>
        <div className="border border-l-0 border-t-0 border-gray-800 bg-gray-50 py-2">Wins</div>
        <div className="border border-l-0 border-t-0 border-gray-800 bg-gray-200 py-2">Podiums</div>
        <div className="border border-l-0 border-t-0 border-gray-800 bg-gray-50 py-2">Top Tens</div>
        <div className="border border-l-0 border-t-0 border-gray-800 bg-gray-200 py-2">DNFs</div>
      </div>
      <div className="flex flex-col text-center font-mono text-lg font-medium uppercase">
        <div className={`border border-l-0 border-gray-800 py-2 ${recordClasses[1]}`}>
          {secondStats.record.wins} - {secondStats.record.losses} - {secondStats.record.ties}
        </div>
        <div className={`border border-l-0 border-t-0 border-gray-800 py-2 ${winClasses[1]}`}>
          {secondStats.wins}
        </div>
        <div className={`border border-l-0 border-t-0 border-gray-800 py-2 ${podiumClasses[1]}`}>
          {secondStats.podiums}
        </div>
        <div className={`border border-l-0 border-t-0 border-gray-800 py-2 ${topTenClasses[1]}`}>
          {secondStats.top_tens}
        </div>
        <div className={`border border-l-0 border-t-0 border-gray-800 py-2 ${dnfClasses[1]}`}>
          {secondStats.dnfs}
        </div>
      </div>
    </>
  );
};

const HeadToHead = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [headToHeadData, setHeadToHeadData] = useState<HeadToHeadData[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstAthleteId, setFirstAthleteId] = useState<string | undefined>(
    searchParams.get('one') || undefined
  );
  const [secondAthleteId, setSecondAthleteId] = useState<string | undefined>(
    searchParams.get('two') || undefined
  );

  const fetchHeadToHeadData = async () => {
    setIsLoading(true);
    const data = await api(user.accessToken).getAthletesHeadToHead([
      firstAthleteId!!,
      secondAthleteId!!
    ]);
    setHeadToHeadData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (firstAthleteId && secondAthleteId) {
      fetchHeadToHeadData();
    }
  }, [searchParams]);

  let first: HeadToHeadData | undefined;
  let second: HeadToHeadData | undefined;
  let sharedRaces: any[] = [];

  if (headToHeadData.length == 2) {
    [first, second] = headToHeadData;
    sharedRaces = first.results.map((result, index) => {
      return {
        race: result.race,
        results: [result, second!!.results[index]]
      };
    });
  }

  return (
    <>
      <AthletesSubNav />
      <PageHeader title="Head-to-Head" subTitle="Compare trail runners head-to-head" />
      <MainContent>
        <PaddedContent>
          <SectionDescription>
            <span className="leading-loose">
              Data is selectively populated and may not be comprehensive. If you'd like to see a
              specific head-to-head matchup,{' '}
              <a
                className="hyperlink underline"
                href="https://freetrail.com/contact/"
                target="_blank"
              >
                send us a request
              </a>
              .
            </span>
          </SectionDescription>
          <div className="mx-auto mb-16 mt-8 grid max-w-[400px] grid-cols-1 items-end gap-x-16 gap-y-4 sm:max-w-[900px] sm:grid-cols-3">
            <div className="sm:order-1">
              <label className="mb-2 block text-sm">First Athlete</label>
              <AthleteSelect
                onChange={({ value }) => setFirstAthleteId(value)}
                className="w-full"
              />
            </div>
            <div className="sm:order-3">
              <label className="mb-2 block text-sm">Second Athlete</label>
              <AthleteSelect
                onChange={({ value }) => setSecondAthleteId(value)}
                className="w-full"
              />
            </div>
            <div className="sm:order-2">
              <button
                /*
                // @ts-ignore */
                onClick={() => setSearchParams({ one: firstAthleteId, two: secondAthleteId })}
                disabled={!firstAthleteId || !secondAthleteId}
                className="ft-btn haze-button border-purple-haze-700 mx-auto w-full border px-8 py-3 font-medium text-white"
              >
                View Head-to-Head
              </button>
            </div>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            first &&
            second && (
              <>
                <div className="mx-auto mb-16 mt-16 grid max-w-[900px] grid-cols-1 sm:grid-cols-3">
                  <div className="">
                    <AthleteCard athlete={first.athlete} />
                  </div>
                  <div className="flex flex-col">
                    <div className="mx-auto mt-8 border-l border-gray-400 sm:h-[100px]"></div>
                    <p className="mx-auto my-auto py-4 text-center text-xl font-medium">vs.</p>
                    <div className="mx-auto mb-8 border-l border-gray-400 sm:h-[100px]"></div>
                  </div>
                  <div>
                    <AthleteCard athlete={second.athlete} />
                  </div>
                </div>
                <div className="mx-auto mb-16 grid max-w-[900px] grid-cols-3">
                  <StatsComparison firstStats={first} secondStats={second} />
                </div>
                {sharedRaces.length > 0 && (
                  <p className="mb-2 text-xs uppercase leading-loose">
                    Found {sharedRaces.length} head-to-head race(s).
                  </p>
                )}
                <DataTable
                  className="data-table"
                  columns={[
                    {
                      name: 'EVENT',
                      id: 1,
                      sortable: true,
                      selector: (row) => row.race.name,
                      minWidth: '200px',
                      cell: (row) => (
                        <a target="_blank" href={row.race.results_url} className="underline">
                          {row.race.name}
                        </a>
                      )
                    },
                    {
                      name: 'DATE',
                      id: 2,
                      sortable: true,
                      selector: (row) => row.race.date,
                      cell: (row) => row.race.formatted_date
                    },
                    {
                      name: `${first.athlete.first_name[0].toUpperCase()}. ${first.athlete.last_name.toUpperCase()} (FIELD / OVERALL / TIME)`,
                      id: 3,
                      sortable: true,
                      minWidth: '150px',
                      selector: (row) => {
                        const result = row.results[0];
                        return result.dnf
                          ? 'DNF'
                          : `${result.place_field} / ${result.place_overall} / ${result.formatted_finish_time}`;
                      }
                    },
                    {
                      name: `${second.athlete.first_name[0].toUpperCase()}. ${second.athlete.last_name.toUpperCase()} (FIELD / OVERALL / TIME)`,
                      id: 4,
                      sortable: true,
                      minWidth: '150px',
                      selector: (row) => {
                        const result = row.results[1];
                        return result.dnf
                          ? 'DNF'
                          : `${result.place_field} / ${result.place_overall} / ${result.formatted_finish_time}`;
                      }
                    }
                  ]}
                  customStyles={{
                    head: {
                      style: { color: '#fff', fontWeight: 400 }
                    },
                    headRow: {
                      style: { backgroundColor: '#111' }
                    },
                    rows: {
                      style: {
                        minHeight: 60
                      }
                    }
                  }}
                  data={sharedRaces}
                  defaultSortFieldId={2}
                  defaultSortAsc={false}
                  responsive
                  noDataComponent={
                    <p className="text-sm">No head-to-head results found for these athletes</p>
                  }
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                />
              </>
            )
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default HeadToHead;
