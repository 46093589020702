import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import MainContent from '../components/main-content';

const NotFound = () => (
  <>
    <MainContent>
      <div className="min-w-full px-4 py-28 text-center align-middle lg:px-8">
        <p className="text-4xl font-bold text-black">You’re off course!</p>
        <p className="mt-8 text-lg text-gray-500">Hmmm... this page doesn't exist.</p>
        <Link to="/events" className="bg-lazer-lemon-500 mt-8 inline-block px-6 py-4 font-mono">
          Back to Events
        </Link>
      </div>
    </MainContent>
    <Footer />
  </>
);

export default NotFound;
