import React, { useCallback, useState } from 'react';
import { League } from '../lib/model';
import { getAssetUrl } from '../lib/assets';

interface LeagueSettingsFormProps {
  isSaving: boolean;
  league?: League | undefined;
  onSubmit: (league: { name: string; avatarUrl: string }, onSuccess: () => void) => void;
}

const LEAGUE_AVATAR_OPTIONS = [
  'goat',
  'yellow-gate',
  'skeleton-cowboy',
  'mont-blanc-snow',
  'mont-blanc-sunset',
  'signpost',
  'cactus',
  'falcon',
  'california-sunset',
  'stump',
  'lightning',
  'jackrabbit'
].map((name) => getAssetUrl(`league-avatars/${name}.png`));

const LeagueSettingsForm: React.FC<LeagueSettingsFormProps> = ({ isSaving, league, onSubmit }) => {
  const [showSaved, setShowSaved] = useState(false);
  const [settings, setSettings] = useState(
    league === undefined
      ? { name: '', avatarUrl: '' }
      : { name: league.name, avatarUrl: league.avatar_url }
  );
  const submitDisabled = !settings.name || !settings.avatarUrl || isSaving;

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(settings, () => {
        if (league !== undefined) {
          setShowSaved(true);
          setTimeout(() => setShowSaved(false), 2000);
        }
      });
    },
    [settings]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="pb-6">
          <div className="grid grid-cols-1 gap-x-6">
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                League Name
              </label>
              <div className="mt-2">
                <div className="focus-within:ring-purple-haze-500 ring-purple-haze-100 flex shadow-sm ring-2 ring-inset ring-inset focus-within:ring-2 focus-within:ring-inset">
                  <input
                    type="text"
                    value={settings.name}
                    onChange={(e) => setSettings({ ...settings, name: e.target.value })}
                    autoFocus={league === undefined}
                    name="name"
                    id="name"
                    className="block flex-1 border-0 bg-transparent px-4 py-3 text-xs text-gray-900 placeholder:text-gray-400 focus:ring-0"
                  />
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="avatar" className="block text-sm font-medium leading-6 text-gray-900">
                League Avatar
              </label>
              <div className="mt-2">
                <div className="grid max-w-[400px] grid-cols-4 gap-x-2 gap-y-2 sm:grid-cols-6">
                  {LEAGUE_AVATAR_OPTIONS.map((url, i) => (
                    <button
                      key={`avatar-${i}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setSettings({
                          ...settings,
                          avatarUrl: url
                        });
                      }}
                      className="group"
                    >
                      <img
                        src={url}
                        className={
                          url === settings.avatarUrl
                            ? 'border-purple-haze-500 rounded border-2 opacity-100'
                            : 'group-hover:border-purple-haze-500 rounded border-2 border-white opacity-50 group-hover:opacity-100'
                        }
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <button type="submit" disabled={submitDisabled} className="ft-btn haze-button px-4 py-3">
          {isSaving ? 'Saving...' : league === undefined ? 'Create League' : 'Save Settings'}
        </button>
        {showSaved && (
          <span className="ml-4 font-mono text-sm uppercase text-gray-600">Saved!</span>
        )}
      </div>
    </form>
  );
};

export default LeagueSettingsForm;
