import React from 'react';

interface MainContentProps {
  hasMinHeight?: boolean;
}

const MainContent: React.FC<React.PropsWithChildren<MainContentProps>> = ({
  children,
  hasMinHeight = true
}) => (
  <main>
    <div className={`main-content ${hasMinHeight ? 'min-h-[650px]' : ''}`}>
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">{children}</div>
      </div>
    </div>
  </main>
);

export const PaddedContent: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="min-w-full px-4 py-2 align-middle lg:px-8">{children}</div>
);

export default MainContent;
