import { parseISO, format } from 'date-fns';

// Remove accents from string
export const normalize = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

// Format datetime
export function formatDatetime(datetime: string): string {
  return format(parseISO(datetime), 'MMM d, yyyy');
}

// Format placement: e.g., 1st, 2nd, 3rd, 4th
export function withOrdinalSuffix(n: number): string {
  return `${n}${[, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th'}`;
}
