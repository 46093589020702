import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageHeader from '../../components/page-header';
import Footer from '../../components/footer';
import UserContext from '../../context/user-context';
import api from '../../lib/api';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import Loading from '../../components/loading';
import { SplitLocation, SplitRace, SplitRunner } from '../../lib/model';
import MainContent, { PaddedContent } from '../../components/main-content';
import ExportCSVButton, { downloadCSV } from '../../components/export-csv-button';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { TrashIcon } from '@heroicons/react/solid';

const customTableStyles = {
  head: {
    style: {
      color: '#fff',
      fontWeight: 400
    }
  },
  header: {
    style: {
      backgroundColor: '#ededed',
      fontSize: '0.75rem'
    }
  },
  headCells: {
    style: {
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      borderRightColor: '#999'
    }
  },
  headRow: {
    style: { backgroundColor: '#111' }
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      wordBreak: 'break-word',
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      borderRightColor: '#ddd'
    },
    draggingStyle: {}
  },
  rows: {
    selectedHighlightStyle: {
      '&:nth-of-type(n)': {
        backgroundColor: 'rgb(240, 248, 121)',
        '> .rdt_TableCell': {
          backgroundColor: 'rgb(240, 248, 121) !important'
        }
      }
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(240, 248, 121)',
      '> .rdt_TableCell': {
        backgroundColor: 'rgb(240, 248, 121) !important'
      }
    }
  }
};

const sortFunction = (fn) => {
  return (rowA, rowB) => {
    const [a, b] = [fn(rowA), fn(rowB)];

    if (a === null && b === null) {
      return 0; // No change in position
    }

    // Check if a is empty
    if (a === null) {
      return 1; // Move a to the end
    }

    // Check if b is empty
    if (b === null) {
      return -1; // Move b to the end
    }

    // Compare a and b for ascending order
    if (a < b) {
      return -1; // a should come before b
    }
    if (a > b) {
      return 1; // a should come after b
    }
    return 0; // a and b are equal
  };
};

const secondsToDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(1, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

type RaceOption = {
  name: string;
  uuid: string;
};

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: '2px solid rgba(132, 88, 126, 1)',
    backgroundColor: 'rgba(132, 88, 126, 0.05)',
    padding: '1px',
    boxShadow: '0 !important',
    '&:hover': {
      border: '2px solid rgb(132, 88, 126)'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eef766' : '',
    color: state.isSelected ? 'black' : ''
  })
};

interface RaceSelectProps {
  raceOptions: RaceOption[];
  onChange: any;
  value?: string;
}

const RaceSelect = ({ raceOptions, onChange, value }: PropsWithChildren<RaceSelectProps>) => {
  const options = raceOptions.map((option) => ({ value: option.uuid, label: option.name }));

  return (
    <div>
      <label className="mb-2 block text-sm font-medium">Race</label>
      <Select
        className="
          z-50
          block
          w-full
          text-xs
          font-normal
          text-black"
        options={options}
        onChange={onChange}
        value={options.find((option) => option.value === value)}
        styles={selectStyles}
        placeholder="Select race"
      />
    </div>
  );
};

interface YearSelectProps {
  yearOptions: number[];
  onChange: ({ value }: { value: any[] }) => void;
  value: number[];
}

const YearSelect = ({ yearOptions, onChange, value }: PropsWithChildren<YearSelectProps>) => {
  return (
    <div>
      <label className="mb-2 block text-sm font-medium">Year</label>
      <Select
        className="
          z-40
          block
          w-full
          text-xs
          font-normal
          text-black"
        options={yearOptions
          .sort((a, b) => b - a)
          .map((option) => ({ value: option, label: option }))}
        isMulti
        onChange={(e) => onChange({ value: e.map((option) => option.value) })}
        value={value.map((option) => ({ value: option, label: option }))}
        styles={selectStyles}
        placeholder="Select years"
      />
    </div>
  );
};

const fieldToLabel = {
  M: 'Men',
  F: 'Women',
  '': 'All'
};

const FieldSelect = ({ onChange, value }: { onChange: any; value: string }) => {
  return (
    <div>
      <label className="mb-2 block text-sm font-medium">Field</label>
      <Select
        className="
          z-30
          block
          w-full
          text-xs
          font-normal
          text-black"
        options={Object.entries(fieldToLabel).map(([value, label]) => ({ value, label }))}
        onChange={onChange}
        value={{ value: value, label: fieldToLabel[value] }}
        styles={selectStyles}
        placeholder="Select field"
      />
    </div>
  );
};

interface LocationSelectProps {
  locationOptions: SplitLocation[];
  onChange: ({ value }: { value: any[] }) => void;
  value: SplitLocation[];
}

const LocationSelect = ({
  locationOptions,
  onChange,
  value
}: PropsWithChildren<LocationSelectProps>) => {
  return (
    <div>
      <label className="mb-2 block text-sm font-medium">Timing Points</label>
      <Select
        className="
          z-20
          block
          w-full
          text-xs
          font-normal
          text-black"
        options={locationOptions.map((option) => ({ value: option.id, label: option.name }))}
        isMulti
        onChange={(e) => onChange({ value: e.map((option) => option.value) })}
        value={value.map((option) => ({ value: option.id, label: option.name }))}
        styles={selectStyles}
        placeholder="Select timing points"
      />
    </div>
  );
};

interface RunnerSelectProps {
  runners: SplitRunner[];
  onChange: ({ value }: { value: any[] }) => void;
  value: string[];
}

const RunnerSelect = ({ runners, onChange, value }: PropsWithChildren<RunnerSelectProps>) => {
  const options = React.useMemo(
    () =>
      [...new Set(runners.map((runner) => `${runner.first_name} ${runner.last_name}`))].map(
        (runner) => ({ value: runner, label: runner })
      ),
    [runners]
  );

  return (
    <div>
      <label className="mb-2 block text-sm font-medium">Runners</label>
      <Select
        className="
          z-20
          block
          w-full
          text-xs
          font-normal
          text-black"
        options={options}
        isMulti
        onChange={(e) => onChange({ value: e.map((option) => option.value) })}
        value={value.map((option) => ({ value: option, label: option }))}
        styles={selectStyles}
        placeholder="Select runners"
      />
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }: any): any => {
  if (active && payload && payload.length) {
    const ordered = payload.slice().sort((a, b) => a.value - b.value);

    return (
      <div className="border-2 border-gray-400 bg-white p-4">
        <p className="mb-2 font-medium">{`${payload[0].payload.name} - ${payload[0].payload.distance_miles} mi`}</p>
        {ordered.map((split, i) => (
          <p
            style={{ color: split.stroke }}
            className="desc"
            key={`${split.name} - ${split.value}`}
          >
            {split.name} - {secondsToDuration(split.value)}
            {i > 0 && ` (+${secondsToDuration(split.value - ordered[0].value)})`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const RaceProgressChart = ({
  runs,
  locations
}: {
  runs: SplitRunner[];
  locations: SplitLocation[];
}) => {
  const [runColors, setRunColors] = useState<{ [key: string]: string }>({});
  const runLabel = React.useCallback(
    (run: SplitRunner) => `${run.first_name} ${run.last_name} (${run.year})`,
    []
  );
  const colors = React.useMemo(
    () => [
      '#3366cc',
      '#dc3912',
      '#ff9900',
      '#109618',
      '#990099',
      '#0099c6',
      '#dd4477',
      '#66aa00',
      '#b82e2e',
      '#316395',
      '#e67300', // Orange
      '#8c564b', // Brown
      '#c49c94', // Light Brown
      '#bcbd22', // Yellow-Green
      '#17becf', // Cyan
      '#1f77b4', // Blue
      '#ff7f0e', // Orange
      '#2ca02c', // Green
      '#d62728', // Red
      '#9467bd', // Purple
      '#8c564b', // Brown
      '#e377c2', // Pink
      '#7f7f7f', // Gray
      '#bcbd22', // Olive
      '#17becf', // Teal
      '#393b79', // Dark Blue
      '#637939', // Dark Olive
      '#8c6d31', // Dark Tan
      '#843c39', // Dark Red
      '#7b4173' // Dark Magenta
    ],
    []
  );

  useEffect(() => {
    const currentColors = { ...runColors };

    // Remove colors for runs that no longer exist
    Object.keys(currentColors).forEach((uuid) => {
      if (!runs.find((run) => run.uuid === uuid)) {
        delete currentColors[uuid];
      }
    });

    let usedColors = new Set(Object.values(currentColors));
    let availableColors = colors.filter((color) => !usedColors.has(color));
    if (availableColors.length === 0) {
      availableColors = colors;
    }

    // Add colors for new runs, first assigning colors that have not been used
    runs.forEach((run, i) => {
      if (!currentColors[run.uuid]) {
        currentColors[run.uuid] = availableColors[i % availableColors.length];
      }
    });

    setRunColors(currentColors);
  }, [runs]);

  const data = React.useMemo(() => {
    const sharedLocationIds = runs
      .map((run) => run.runner_splits.flatMap((split) => split.checkpoint.split_location_id))
      .reduce((a, b) => a.filter((locationId) => b.includes(locationId)));

    const sharedLocations = locations.filter((location) => sharedLocationIds.includes(location.id));

    return sharedLocations.map((location) => {
      const locationData = { name: location.name, distance_miles: location.distance_miles };
      runs.forEach((run) => {
        locationData[runLabel(run)] = run.runner_splits.find(
          (split) => split.checkpoint.split_location_id === location.id
        )?.time_seconds;
      });
      return locationData;
    });
  }, [runs, locations]);

  return (
    <>
      <h2 className="mb-2 text-sm font-medium">Race Progression</h2>
      <LineChart
        width={800}
        height={400}
        style={{
          fontSize: '0.75rem',
          border: '1px solid #666',
          padding: 20,
          maxWidth: '100%'
        }}
        data={data}
        margin={{ left: 20, right: 20, top: 40, bottom: 20 }}
      >
        <CartesianGrid
          stroke="#ccc"
          fill="rgb(132, 88, 126)"
          fillOpacity={0.05}
          strokeDasharray="3 3"
        />
        <XAxis
          dataKey="distance_miles"
          label={{ value: 'Distance (mi)', position: 'insideBottomRight', offset: -10 }}
          scale="linear"
          type="number"
          domain={[
            Math.floor(locations[0].distance_miles),
            Math.ceil(locations[locations.length - 1].distance_miles)
          ]}
        />
        <YAxis
          label={{ value: 'Race Time', angle: -90, position: 'insideLeft', offset: -20 }}
          tickFormatter={(value) => secondsToDuration(value)}
          scale="linear"
          type="number"
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend align="center" layout="horizontal" />
        {runs.map((run, index) => (
          <Line
            type="monotone"
            key={run.uuid}
            dataKey={runLabel(run)}
            strokeWidth={3}
            stroke={runColors[run.uuid]}
            connectNulls
          />
        ))}
      </LineChart>
    </>
  );
};

interface SearchFilters {
  years: number[];
  field: string;
  runners: string[];
  locationIds: string[];
}

const Splits = () => {
  const user = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [raceId, setRaceId] = useState(searchParams.get('race') || undefined);
  const [years, setYears] = useState<number[]>([]);
  const [field, setField] = useState<string>('');
  const [races, setRaces] = useState<SplitRace[]>([]);
  const [runs, setRuns] = useState<SplitRunner[]>([]);
  const [page, setPage] = useState(1);
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [pastQueries, setPastQueries] = useState<{ [key: string]: SearchFilters }>(() => {
    const saved = localStorage.getItem('splitsPastQueries');
    // Guard against stale data in localStorage
    try {
      return saved ? JSON.parse(saved) : {};
    } catch (e) {
      return {};
    }
  });
  const [runners, setRunners] = useState<string[]>([]);
  const [selectedRuns, setSelectedRuns] = useState<SplitRunner[]>([]);
  const [toggleClearSelectedRuns, setToggleClearRuns] = useState(false);

  const selectedRace = React.useMemo(
    () => races.find((race) => race.uuid === raceId),
    [races, raceId]
  );
  const commonLocationIds = React.useMemo(
    () =>
      selectedRace?.occurrences
        .filter((o) => years.length === 0 || years.includes(o.year))
        .map((o) => o.checkpoints.map((c) => c.split_location_id))
        .reduce((a, b) => a.filter((locationId) => b.includes(locationId))),
    [selectedRace, years]
  );
  const commonLocations = React.useMemo(
    () => selectedRace?.locations.filter((location) => commonLocationIds?.includes(location.id)),
    [selectedRace, commonLocationIds]
  );
  const filteredLocations = React.useMemo(
    () =>
      commonLocations?.filter(
        (location) => locationIds.length === 0 || locationIds.includes(location.id)
      ) || [],
    [commonLocations, locationIds]
  );

  const filteredRuns = React.useMemo(
    () =>
      runs.filter(
        (run) =>
          (years.includes(run.year) || years.length === 0) &&
          (run.field === field || !field) &&
          (runners.includes(`${run.first_name} ${run.last_name}`) || runners.length === 0)
      ),
    [runs, years, field, runners]
  );

  const getRunnerSplits = React.useCallback(
    (
      runner: SplitRunner,
      location: SplitLocation,
      locationIndex: number | undefined = undefined
    ): [any, any] => {
      const split =
        runner.runner_splits.find((split) => split.checkpoint.split_location_id === location.id) ||
        null;

      let nextSplit = null;
      if (locationIndex !== undefined) {
        const nextLocationId = filteredLocations[locationIndex + 1]?.id;
        if (nextLocationId) {
          nextSplit =
            runner.runner_splits.find(
              (split) => split.checkpoint.split_location_id === nextLocationId
            ) || null;
        }
      }

      return [split, nextSplit];
    },
    [filteredRuns, filteredLocations]
  );

  const handleSelectedRowsChange = React.useCallback(
    ({ selectedRows }) => setSelectedRuns(selectedRows),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const data = await api(user.accessToken).getSplits(raceId || '');

      setRaces(data.races);
      setRuns(data.runs);

      setIsLoading(false);
    };

    fetchData();
  }, [user.isAuthenticated, raceId]);

  const columns = React.useMemo(
    () => [
      {
        id: 9000,
        name: '#',
        selector: (row, index) => (index === undefined ? '' : (page - 1) * 100 + index + 1),
        maxWidth: '60px',
        minWidth: '60px',
        grow: 0
      },
      {
        id: 1,
        name: 'Year',
        maxWidth: '80px',
        minWidth: '80px',
        selector: (row) => row.year,
        sortable: true
      },
      {
        id: 2,
        name: 'First Name',
        grow: 1,
        selector: (row) => row.first_name,
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px'
      },
      {
        id: 3,
        name: 'Last Name',
        grow: 1,
        selector: (row) => row.last_name,
        sortable: true,
        minWidth: '120px'
      },
      {
        id: 90,
        name: 'Field',
        grow: 0,
        selector: (row) => row.field,
        sortable: true,
        maxWidth: '80px',
        minWidth: '80px'
      },
      {
        id: 4,
        name: 'Finish Place',
        selector: (row) => row.finish_place_overall || '',
        format: (row) => {
          if (!row.finish_time_seconds) return '';

          return (
            row.finish_place_overall?.toLocaleString() +
            (row.finish_place_field ? ` (${row.field}${row.finish_place_field})` : '')
          );
        },
        sortable: true,
        minWidth: '120px'
      },
      {
        id: 5,
        name: 'Finish Time',
        selector: (row) => row.finish_time_seconds || '',
        format: (row) => row.finish_time_formatted.toLocaleString(),
        sortable: true,
        sortFunction: sortFunction((row) => row.finish_time_seconds),
        minWidth: '120px'
      },
      ...(filteredLocations || [])
        .map((location, index) => [
          {
            id: index + 6,
            name: `${location.name} (${location.distance_miles})`,
            selector: (row) => {
              const [split, _] = getRunnerSplits(row, location);
              return split?.time_seconds;
            },
            format: (row) => {
              const [split, _] = getRunnerSplits(row, location);
              return [
                split?.time_formatted,
                split?.position_overall && `(${split.position_overall})`
              ]
                .filter(Boolean)
                .join(' ');
            },
            sortable: true,
            grow: 2,
            sortFunction: sortFunction((row) => getRunnerSplits(row, location)[0]?.time_seconds),
            minWidth: `${Math.max(100, 100 + location.name.length * 5.5)}px`
          },
          ...(index < filteredLocations.length - 1
            ? [
                {
                  id: index + 1000,
                  name: '𝚫',
                  selector: (row) => {
                    const [split, nextSplit] = getRunnerSplits(row, location, index);
                    if (split === null || nextSplit === null) return '';
                    if (split.time_seconds === null || nextSplit.time_seconds === null) return '';

                    return secondsToDuration(nextSplit.time_seconds - split.time_seconds);
                  },
                  sortable: true,
                  sortFunction: sortFunction((row) => {
                    const [split, nextSplit] = getRunnerSplits(row, location, index);
                    if (split === null || nextSplit === null) return null;
                    if (split.time_seconds === null || nextSplit.time_seconds === null) return null;

                    return nextSplit.time_seconds - split.time_seconds;
                  }),
                  minWidth: '100px'
                }
              ]
            : [])
        ])
        .flat()
        .filter(Boolean)
    ],
    [filteredLocations, getRunnerSplits]
  );

  const actionsMemo = React.useMemo(
    () =>
      filteredRuns.length > 0 ? (
        <>
          <button
            className="mr-2 rounded-sm bg-gray-600 px-3 py-2 font-mono text-white hover:bg-gray-700 disabled:bg-gray-400"
            disabled={selectedRuns.length === 0}
            onClick={() => {
              setToggleClearRuns(!toggleClearSelectedRuns);
              setSelectedRuns([]);
            }}
          >
            Deselect All
          </button>
          <ExportCSVButton
            className="bg-purple-haze-500 hover:bg-purple-haze-600 disabled:bg-purple-haze-300 mr-2 rounded-sm px-3 py-2 font-mono text-white"
            onExport={() =>
              downloadCSV(
                (selectedRuns.length > 0 ? selectedRuns : filteredRuns).map((run, i) => {
                  let csvRow = columns.reduce((acc, column, j) => {
                    acc[column.name === '𝚫' ? `𝚫 to ${columns[j + 1].name}` : column.name] =
                      column.format ? column.format(run) : column.selector(run, i);
                    return acc;
                  }, {});
                  delete csvRow['#'];
                  return csvRow;
                }),
                'splits.csv'
              )
            }
          >
            {selectedRuns.length > 0 ? 'Download Selected (CSV)' : 'Download (CSV)'}
          </ExportCSVButton>
        </>
      ) : null,
    [filteredRuns, selectedRuns, toggleClearSelectedRuns]
  );

  const setFilters = React.useCallback((filters: SearchFilters) => {
    setRunners(filters.runners);
    setYears(filters.years);
    setField(filters.field);
    setLocationIds(filters.locationIds);
  }, []);

  const updatePastQueries = React.useCallback(
    (newPastQueries: { [key: string]: SearchFilters }) => {
      localStorage.setItem('splitsPastQueries', JSON.stringify(newPastQueries));
      setPastQueries(newPastQueries);
    },
    []
  );

  const handleSearch = React.useCallback(
    async (e) => {
      e.preventDefault();
      const memoizedQuery = e.target[0].value;

      setQuery('');
      setIsLoadingFilters(true);

      const response = await api(user.accessToken).getSplitFilters(raceId!!, memoizedQuery);
      const func = response.choices?.[0]?.message?.tool_calls?.[0]?.function;

      if (func && func.name === 'filterRunners') {
        const args = JSON.parse(func.arguments);

        const argsRunners = args.names || [];
        const argsYears = args.years || [];
        const argsField =
          Object.entries(fieldToLabel).find(([_, label]) => label === args.field)?.[0] || '';
        const argsLocations =
          selectedRace!!.locations
            .filter((location) => (args.timingPoints || []).includes(location.name))
            .map((location) => location.id) || [];

        const newPastQueries = {
          ...pastQueries,
          [memoizedQuery]: {
            runners: argsRunners,
            years: argsYears,
            field: argsField,
            locationIds: argsLocations
          }
        };

        updatePastQueries(newPastQueries);
        setFilters({
          runners: argsRunners,
          years: argsYears,
          field: argsField,
          locationIds: argsLocations
        });
      }

      setIsLoadingFilters(false);
    },
    [raceId, selectedRace, user.accessToken, pastQueries]
  );

  return (
    <>
      <PageHeader title="Splits" subTitle="View historical split times for trail races" />
      <MainContent>
        <PaddedContent>
          {runs.length > 0 && showChat && (
            <div className="mb-8 border-b border-gray-300 pb-8">
              <form onSubmit={handleSearch}>
                <label className="mb-2 block text-sm font-medium">Search</label>
                <input
                  type="text"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                  className="bg-purple-haze-50/20 focus:outline-none border-purple-haze-500 focus:border-purple-haze-500 block w-full rounded border-2 p-4 text-sm focus:ring-0"
                  placeholder="Ex. Courtney's Foresthill splits from 2019 and 2023"
                />
              </form>
              <div className="mt-4">
                {Object.keys(pastQueries).map((query, i) => (
                  <div
                    className="bg-purple-haze-500 mb-3 mr-2 inline-block rounded-md text-xs text-white hover:cursor-pointer"
                    key={`query-${i}`}
                  >
                    <p
                      onClick={() => setFilters(pastQueries[query])}
                      className="hover:bg-purple-haze-600 inline-block rounded-l-md border-r border-white px-3 py-2"
                    >
                      {query}
                    </p>
                    <button
                      onClick={() => {
                        let newPastQueries = { ...pastQueries };
                        delete newPastQueries[query];
                        updatePastQueries(newPastQueries);
                      }}
                      className="hover:bg-purple-haze-600 rounded-r-md px-2 py-2 text-white"
                    >
                      <TrashIcon className="h-3 w-3" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {isLoadingFilters ? (
            <div className="my-20 text-center">
              <p className="mb-8 text-sm">Updating filters...</p>
              <div className="mx-auto w-24">
                <Loading />
              </div>
            </div>
          ) : (
            <>
              <div className="mb-4 grid grid-cols-1 space-x-0 space-y-4 sm:grid-cols-4 sm:space-x-4 sm:space-y-0 md:grid-cols-5">
                <RaceSelect
                  value={raceId}
                  raceOptions={races}
                  onChange={({ value }) => {
                    setSearchParams({ race: value });
                    setRaceId(value);
                  }}
                />
                {runs.length > 0 && selectedRace !== undefined && (
                  <YearSelect
                    value={years}
                    yearOptions={selectedRace.occurrences.map((o) => o.year)}
                    onChange={({ value }) => setYears(value)}
                  />
                )}
                {runs.length > 0 && (
                  <FieldSelect value={field} onChange={({ value }) => setField(value)} />
                )}
                {runs.length > 0 && commonLocations !== undefined && (
                  <LocationSelect
                    value={commonLocations.filter((location) => locationIds.includes(location.id))}
                    locationOptions={commonLocations}
                    onChange={({ value }) => setLocationIds(value)}
                  />
                )}
              </div>
              {runs.length > 0 && (
                <div className="mb-8 grid grid-cols-1 space-x-4 sm:grid-cols-4 md:grid-cols-5">
                  <RunnerSelect
                    value={runners}
                    runners={runs}
                    onChange={({ value }) => setRunners(value)}
                  />
                </div>
              )}
            </>
          )}
          {runs.length > 0 && (
            <div
              className="flex flex-row justify-between"
              onClick={(e) => e.detail === 5 && setShowChat(true)}
            >
              {selectedRace && selectedRace.name === 'Western States' && (
                <p className="mb-4 text-xs text-gray-800">
                  Data backfilled through 2012. Source:{' '}
                  <a
                    href="https://www.wser.org/splits/"
                    className="hyperlink underline"
                    target="_blank"
                  >
                    https://www.wser.org/splits
                  </a>{' '}
                </p>
              )}
              {selectedRace && selectedRace.name.includes('UTMB') && (
                <p className="mb-4 text-xs text-gray-800">
                  Data backfilled for the top 20 women and men each year through 2018. Note that
                  exact distances can vary from year to year with course changes. Source:{' '}
                  <a
                    href="https://livetrail.net/histo/utmb_2023/classement.php"
                    className="hyperlink underline"
                    target="_blank"
                  >
                    LiveTrail
                  </a>{' '}
                </p>
              )}
            </div>
          )}
          {selectedRuns.length > 0 && (
            <div className="mb-8 mt-4 min-h-[400px] w-full max-w-full">
              <RaceProgressChart runs={selectedRuns} locations={filteredLocations} />
            </div>
          )}
        </PaddedContent>
        <DataTable
          className="data-table splits-table"
          highlightOnHover
          columns={columns}
          customStyles={customTableStyles}
          data={filteredRuns}
          dense
          defaultSortFieldId={5}
          defaultSortAsc
          progressPending={isLoading}
          progressComponent={
            raceId ? (
              <div className="mt-8 text-center">
                <p className="mb-8 text-sm">Loading splits...</p>
                <div className="mx-auto w-24">
                  <Loading />
                </div>
              </div>
            ) : (
              <span></span>
            )
          }
          noDataComponent={
            runs.length === 0 ? (
              <div className="mt-8 text-center text-sm">Select a race to load splits.</div>
            ) : (
              <div className="mt-8 text-center text-sm">No results found.</div>
            )
          }
          actions={actionsMemo}
          noContextMenu
          expandOnRowClicked={false}
          expandableRows
          expandableRowsHideExpander
          selectableRowsNoSelectAll
          fixedHeader
          onChangePage={(page) => setPage(page)}
          striped
          selectableRows
          selectableRowsHighlight
          clearSelectedRows={toggleClearSelectedRuns}
          onSelectedRowsChange={handleSelectedRowsChange}
          pagination
          paginationPerPage={100}
        />
      </MainContent>
      <Footer />
    </>
  );
};

export default Splits;
