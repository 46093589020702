import React, { PropsWithChildren } from 'react';

interface FlashMessageProps {
  type: 'notice' | 'info' | 'announcement';
  small?: boolean;
}

const FlashMessage = ({ type, small = false, children }: PropsWithChildren<FlashMessageProps>) => {
  let bgColorClass = '';
  let borderColorClass = '';
  let textColorClass = 'text-zinc-900';

  switch (type) {
    case 'notice':
      bgColorClass = 'bg-lazer-lemon-500';
      borderColorClass = 'border-lazer-lemon-900';
      break;
    case 'info':
      bgColorClass = 'bg-slate-200';
      borderColorClass = 'border-slate-300';
      break;
    case 'announcement':
      bgColorClass = 'bg-purple-haze-500';
      borderColorClass = 'border-purple-haze-700';
      textColorClass = 'text-white';
      break;
  }

  return (
    <p
      className={`my-5 mt-1 border p-3 ${textColorClass} ${bgColorClass} ${borderColorClass} ${
        small ? 'inline-block text-xs' : 'text-sm'
      }`}
    >
      {children}
    </p>
  );
};

export default FlashMessage;
