import React, { PropsWithChildren, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import UserContext from '../context/user-context';
import api from '../lib/api';

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: '2px solid rgba(132, 88, 126, 0.6)',
    padding: 4,
    boxShadow: '0 !important',
    '&:hover': {
      border: '2px solid rgb(132, 88, 126)'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eef766' : '',
    color: state.isSelected ? 'black' : ''
  })
};

interface AthleteSelectProps {
  className?: string;
  onChange: ({ value }: { value: string }) => void;
}

const AthleteSelect = ({ onChange, className }: PropsWithChildren<AthleteSelectProps>) => {
  const user = useContext(UserContext);

  const loadOptions = async (inputValue: string) => {
    if (inputValue.length < 3) return [];
    const data = await api(user.accessToken).getAthletes(inputValue);
    return data.athletes.map((athlete) => ({
      value: athlete.uuid,
      label: `${athlete.first_name} ${athlete.last_name}`,
      imageUrl: athlete.profile_image_urls.thumb
    }));
  };

  return (
    <AsyncSelect
      className={`
          min-w-64
          block
          text-sm
          font-normal
          text-gray-700 ${className || ''}`}
      cacheOptions
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder="Search for an athlete"
      noOptionsMessage={({ inputValue }) =>
        inputValue.length < 3 ? 'Enter at least 3 characters' : 'No athletes found'
      }
      styles={selectStyles}
      formatOptionLabel={({ value, label, imageUrl }) => {
        return (
          <div>
            <img src={imageUrl} className="mr-2 inline h-8 w-8 rounded-full" />
            <span>{label}</span>
          </div>
        );
      }}
    />
  );
};

export default AthleteSelect;
