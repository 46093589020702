import React from 'react';
import Flags from 'country-flag-icons/react/3x2';

interface NationalityFlagProps {
  country: string | undefined;
  showCountryName?: boolean;
}

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const NationalityFlag = ({ country, showCountryName }: NationalityFlagProps) => {
  const Flag = Flags[country || ''];

  return (
    (Flag && country !== undefined && (
      <span>
        <Flag className="inline-block h-5 w-5 flex-shrink-0" />
        {showCountryName && <span className="ml-2">{regionNames.of(country)}</span>}
      </span>
    )) ||
    null
  );
};

export default NationalityFlag;
