import React, { PropsWithChildren } from 'react';
import Select from 'react-select';

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: '2px solid rgba(132, 88, 126, 0.6)',
    padding: 4,
    boxShadow: '0 !important',
    '&:hover': {
      border: '2px solid rgb(132, 88, 126)'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eef766' : '',
    color: state.isSelected ? 'black' : ''
  })
};

interface SeasonSelectProps {
  onChange: ({ value }: { value: string }) => void;
  year?: number;
  maxYear?: number;
}

const SeasonSelect = ({ onChange, year, maxYear }: PropsWithChildren<SeasonSelectProps>) => {
  const currentYear = new Date().getFullYear();

  const yearOptions = [2022];
  while (yearOptions[0] < (maxYear || currentYear)) {
    yearOptions.unshift(yearOptions[0] + 1);
  }

  const defaultYear = year || yearOptions[0];

  return (
    <>
      <label className="mb-2 block text-sm font-medium">Season</label>
      <Select
        className="
          mb-6
          block
          w-48
          text-sm
          font-normal
          text-gray-700"
        defaultValue={{ value: defaultYear, label: defaultYear }}
        options={yearOptions.map((year) => ({ value: year, label: year }))}
        onChange={onChange}
        styles={selectStyles}
      />
    </>
  );
};

export default SeasonSelect;
