const REDIRECT_TO_KEY = 'redirectTo';

const loginAndRedirect = (loginWithRedirect) => {
  localStorage.setItem(REDIRECT_TO_KEY, window.location.href);
  loginWithRedirect();
};

const postAuthRedirect = () => {
  const redirectTo = localStorage.getItem(REDIRECT_TO_KEY);

  if (redirectTo) {
    localStorage.removeItem(REDIRECT_TO_KEY);
    window.location.href = redirectTo;
  }
};

export { loginAndRedirect, postAuthRedirect };
