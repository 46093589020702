import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import {
  CalendarIcon,
  CheckCircleIcon,
  ClipboardCheckIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  ShareIcon,
  UserIcon,
  HashtagIcon,
  CloudUploadIcon
} from '@heroicons/react/solid';
import UserContext from '../context/user-context';
import { useAuth0 } from '@auth0/auth0-react';
import StatusBadge from '../components/status-badge';
import {
  ContestType,
  Entrant,
  EntrantType,
  EventPlatform,
  EventSponsor,
  EventStatus,
  FIELDS,
  FIELD_ALL,
  FIELD_FEMALE,
  FIELD_MALE
} from '../lib/model';
import FlashMessage from '../components/flash-message';
import SectionDescription from '../components/section-description';
import Footer from '../components/footer';
import { loginAndRedirect } from '../lib/auth';
import SeriesBadges from '../components/series-badges';
import Leaderboard from '../components/leaderboard';
import AthleteProfileModal from '../components/athlete-profile-modal';
import { parseISO } from 'date-fns';
import NationalityFlag from '../components/nationality-flag';
import AthleteTeams from '../components/athlete-teams';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Tooltip from '../components/tooltip';
import { normalize, withOrdinalSuffix } from '../lib/strings';
import MainContent, { PaddedContent } from '../components/main-content';
import EntrantName from '../components/entrant-name';
import PicksTable from '../components/picks-table';
import EntrantLocation from '../components/entrant-location';
import ScorecardModal from '../components/scorecard-modal';
import PostSubmissionModal from '../components/post-submission-modal';
import EventCountdown from '../components/event-countdown';

function isNicknameMatch(query: string, entrantName: string): boolean {
  return [
    ['speedgoat', 'Karl Meltzer'],
    ['dbo', 'Dylan Bowman'],
    ['freetrail', 'Dylan Bowman'],
    ['mvd', 'Matt Van Dalsem'],
    ['petergod', 'Adam Peterman']
  ].some(([nickname, fullName]) => {
    return query === nickname && entrantName === fullName;
  });
}

const CopyPicksButton = ({ event, femalePicks, malePicks, isCommunity }) => {
  const [picksCopied, setPicksCopied] = useState(false);

  const copyPicksToClipboard = () => {
    let whose = isCommunity ? 'the @runfreetrail community' : 'my @runfreetrail';
    let text = `Check out ${whose} picks for ${event.name_and_format}!\n`;

    const emojiForIndex = (i) => {
      switch (i) {
        case 0:
          return '🥇';
        case 1:
          return '🥈';
        case 2:
          return '🥉';
        case 3:
          return '4️⃣';
        case 4:
          return '5️⃣';
        case 5:
          return '6️⃣';
        case 6:
          return '7️⃣';
        case 7:
          return '8️⃣';
        case 8:
          return '9️⃣';
        case 9:
          return '🔟';
      }
    };

    const picksToText = (picks, heading) => {
      if (!picks.length) {
        return '';
      }

      return picks.slice(0, 10).reduce((text, entrant, i) => {
        return (
          text +
          `${emojiForIndex(i)} ${entrant.first_name[0].toUpperCase()}. ${entrant.last_name}` +
          (entrant.result ? ` - ${entrant.result.race.name}` : '') +
          `\n`
        );
      }, `\n${heading}:\n\n`);
    };

    text += picksToText(femalePicks, 'W');
    text += picksToText(malePicks, 'M');

    if (event.hashtag) {
      text += `\n#${event.hashtag}`;
    }

    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: text.trim()
        })
        .then(() => {})
        .catch((error) => console.log('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(text.trim());
      setPicksCopied(true);
    }
  };

  if (!Boolean([...femalePicks, ...malePicks].length)) {
    return null;
  }

  return (
    <button
      className="ft-btn mr-3 inline-block border border-gray-600 bg-gray-600 px-3 py-2 text-white hover:border-gray-700 hover:bg-gray-700"
      onClick={() => copyPicksToClipboard()}
    >
      {picksCopied && (
        <span>
          <ClipboardCheckIcon className="ft-btn-icon" aria-hidden="true" />
          Copied to Clipboard!
        </span>
      )}
      {!picksCopied && (
        <span>
          <ShareIcon className="ft-btn-icon" aria-hidden="true" />
          Share
        </span>
      )}
    </button>
  );
};

const PickTableCell = ({
  classNames,
  entrantPicked,
  entrant,
  addPick,
  addPickDisabled,
  removePick,
  removePickDisabled
}: {
  classNames: string;
  entrantPicked: boolean;
  entrant: any;
  addPick: (entrant: any) => void;
  addPickDisabled: boolean;
  removePick: (entrant: any) => void;
  removePickDisabled: boolean;
}) => {
  return (
    <td className={`td-compact text-center text-sm font-medium ${classNames}`}>
      {entrantPicked ? (
        <button
          className="flex items-center rounded p-2 text-xs disabled:text-gray-300"
          onClick={() => removePick(entrant)}
          title="Remove Pick"
          key={`remove-pick-btn-${entrant.id}`}
          disabled={removePickDisabled}
        >
          <CheckCircleIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
        </button>
      ) : (
        <button
          className="ft-btn haze-button flex items-center p-2 text-xs"
          key={`add-pick-btn-${entrant.id}`}
          onClick={() => addPick(entrant)}
          disabled={addPickDisabled}
        >
          Pick
        </button>
      )}
    </td>
  );
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Event = () => {
  const user = useContext(UserContext);
  const query = useQuery();
  const { loginWithRedirect } = useAuth0();
  const [event, setEvent] = useState({});
  const [leaderboard, setLeaderboard] = useState([]);
  const [entrants, setEntrants] = useState([]);
  const [femalePicks, _setFemalePicks] = useState([]);
  const [malePicks, _setMalePicks] = useState([]);
  const [femaleCommunityPicks, setFemaleCommunityPicks] = useState([]);
  const [maleCommunityPicks, setMaleCommunityPicks] = useState([]);
  const [isLoadingUserPicks, setIsLoadingUserPicks] = useState(true);
  const [isLoadingCommunityPicks, setIsLoadingCommunityPicks] = useState(true);
  const [queryFilter, setQueryFilter] = useState(query.get('q') || '');
  const [fieldFilter, setFieldFilter] = useState(FIELD_ALL);
  const [submitPicksModalOpen, setSubmitPicksModalOpen] = useState(false);
  const [athleteInfoModalOpen, setAthleteInfoModalOpen] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<Entrant | undefined>(undefined);
  const [savingPicks, setSavingPicks] = useState(false);
  const [picksSaved, setPicksSaved] = useState(true);
  const [communitySize, setCommunitySize] = useState(0);
  const [loadFullLeaderboard, setLoadFullLeaderboard] = useState(false);
  const [eventUser, setEventUser] = useState({});
  const [scorecardModalOpen, setScorecardModalOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (user.isLoading) return;

    api(user.accessToken)
      .getEvent(params.eventId)
      .then((response) => {
        response.json().then((body) => {
          setEvent(body.data);

          if (body.data.status === EventStatus.ResultsPosted) {
            api(user.accessToken)
              .getEventLeaderboard(params.eventId)
              .then((response) => {
                response.json().then((body) => {
                  setLeaderboard(body.data.users);
                });
              });
          }

          // Retrieve community picks if event is not open
          if ([EventStatus.Closed, EventStatus.ResultsPosted].includes(body.data.status)) {
            api(user.accessToken)
              .getCommunityPicks(params.eventId)
              .then((response) => {
                response.json().then((body) => {
                  const fPicks = [];
                  const mPicks = [];

                  body.data.picks.forEach((pick) => {
                    switch (pick.entrant.field) {
                      case FIELD_FEMALE:
                        fPicks.push(pick.entrant);
                        break;
                      case FIELD_MALE:
                        mPicks.push(pick.entrant);
                        break;
                    }
                  });

                  setFemaleCommunityPicks(fPicks);
                  setMaleCommunityPicks(mPicks);
                  setCommunitySize(body.data.community_size);
                  setIsLoadingCommunityPicks(false);
                });
              });
          } else {
            setIsLoadingCommunityPicks(false);
          }

          // Retrieve existing event picks for authenticated user
          if (user.isAuthenticated) {
            setIsLoadingUserPicks(true);

            api(user.accessToken)
              .getUserPicks(params.eventId)
              .then((response) => {
                response.json().then((body) => {
                  const fPicks = [];
                  const mPicks = [];

                  body.data.picks.forEach((pick) => {
                    switch (pick.entrant.field) {
                      case FIELD_FEMALE:
                        fPicks.push(pick.entrant);
                        break;
                      case FIELD_MALE:
                        mPicks.push(pick.entrant);
                        break;
                    }
                  });

                  setEventUser(body.data);
                  _setFemalePicks(fPicks);
                  _setMalePicks(mPicks);
                  setIsLoadingUserPicks(false);
                });
              });
          } else {
            setIsLoadingUserPicks(false);
          }
        });
      });
  }, [user.isAuthenticated, user.isLoading]);

  useEffect(() => {
    if (event?.entrants?.length) {
      filterEntrants(queryFilter, FIELD_ALL);
    }
  }, [event.entrants]);

  useEffect(() => {
    if (loadFullLeaderboard) {
      api(user.accessToken)
        .getEventLeaderboard(params.eventId, 2000)
        .then((response) => {
          response.json().then((body) => {
            setLeaderboard(body.data.users);
          });
        });
    }
  }, [user.isAuthenticated, loadFullLeaderboard]);

  const actionsDisabled = event.status !== EventStatus.Open || !user.isAuthenticated;

  const savePicks = (fPicks, mPicks) => {
    if (!user.isAuthenticated) {
      return;
    }

    const picks = [fPicks, mPicks]
      .map((_picks) => {
        return _picks.map((entrant, i) => {
          return {
            entrant_id: entrant.id,
            rank: i + 1
          };
        });
      })
      .flat();

    return api(user.accessToken).createPicks(event.id, picks);
  };

  const setFemalePicks = (picks) => {
    _setFemalePicks(picks);
  };

  const setMalePicks = (picks) => {
    _setMalePicks(picks);
  };

  const doQueryFilter = (query: string) => {
    setQueryFilter(query);
    filterEntrants(query, fieldFilter);
  };

  const doFieldFilter = (field: string) => {
    setFieldFilter(field);
    filterEntrants(queryFilter, field);
  };

  const filterEntrants = (qf: string, ff: string) => {
    const query = qf.toLowerCase();
    if (query === '' && ff === FIELD_ALL) {
      return setEntrants(event.entrants);
    }

    const matches = (value: string) => {
      return normalize((value || '').toLowerCase()).includes(normalize(query));
    };

    setEntrants(
      event.entrants.filter((entrant) => {
        const matchesQuery =
          query === '' ||
          matches(entrant.first_name) ||
          matches(entrant.last_name) ||
          matches(`${entrant.first_name} ${entrant.last_name}`) ||
          matches(entrant.city) ||
          matches(entrant.state_or_country) ||
          isNicknameMatch(query, `${entrant.first_name} ${entrant.last_name}`);

        const matchesField = ff === FIELD_ALL || entrant.field === ff;

        return matchesField && matchesQuery;
      })
    );
  };

  const addPick = (entrant) => {
    switch (entrant.field) {
      case FIELD_FEMALE:
        setFemalePicks([...femalePicks, entrant]);
        break;
      case FIELD_MALE:
        setMalePicks([...malePicks, entrant]);
        break;
    }
    setPicksSaved(false);
  };

  const removePick = (entrant) => {
    switch (entrant.field) {
      case FIELD_FEMALE:
        setFemalePicks(femalePicks.filter((e) => e.id !== entrant.id));
        break;
      case FIELD_MALE:
        setMalePicks(malePicks.filter((e) => e.id !== entrant.id));
        break;
    }
    setPicksSaved(false);
  };

  const movePick = (entrant, oldPosition, newPosition) => {
    let newPicks = [];

    switch (entrant.field) {
      case FIELD_FEMALE:
        newPicks = [...femalePicks];
        newPicks[oldPosition] = femalePicks[newPosition];
        newPicks[newPosition] = femalePicks[oldPosition];
        setFemalePicks(newPicks);
        break;
      case FIELD_MALE:
        newPicks = [...malePicks];
        newPicks[oldPosition] = malePicks[newPosition];
        newPicks[newPosition] = malePicks[oldPosition];
        setMalePicks(newPicks);
        break;
    }

    setPicksSaved(false);
  };

  const submitPicks = () => {
    setSavingPicks(true);
    savePicks(femalePicks, malePicks).then((response) => {
      if (response.ok) {
        setSubmitPicksModalOpen(true);
        setPicksSaved(true);
      } else if (response.status === 422) {
        window.location.reload();
      }

      setSavingPicks(false);
    });
  };

  const showAthleteInfoModal = (entrant) => {
    setSelectedAthlete(entrant);
    setAthleteInfoModalOpen(true);
  };

  const savingPicksDisabled =
    savingPicks ||
    picksSaved ||
    !user.isAuthenticated ||
    actionsDisabled ||
    (malePicks.length === 0 && femalePicks.length === 0);

  const maxPicksPerField = [ContestType.PickFive, ContestType.VoteFive].includes(event.contest_type)
    ? 5
    : 10;
  const isVoteContest = [ContestType.VoteFive, ContestType.VoteTen].includes(event.contest_type);
  const hasPerformanceEntrants = event.entrant_type === EntrantType.Performance;
  const femalePicksFull = femalePicks.length >= maxPicksPerField;
  const malePicksFull = malePicks.length >= maxPicksPerField;
  const onUltraSignup = event.platform === EventPlatform.UltraSignup;
  const userName = user.isAuthenticated ? user.displayName : '';
  const allPicks = [...femalePicks, ...malePicks];
  const eventYear = parseISO(event.closes_at).getFullYear();

  let sponsor: EventSponsor | undefined = undefined;
  if (isVoteContest && eventYear === 2023) {
    sponsor = EventSponsor.KetoneIQ;
  } else if (eventYear === 2024 && event.name.includes('Black Canyon')) {
    sponsor = EventSponsor.HOKA;
  }

  if (!event.id) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={
          <>
            <div className="hidden sm:block">
              <img className="mr-3 inline h-10 w-10 rounded-full" src={event.image_url} />
              <span className="text-xl sm:text-2xl">{event.name_and_format}</span>
            </div>
            <div className="sm:hidden">
              <img className="mx-auto mb-4 h-20 w-20 rounded-full" src={event.image_url} />
              <p className="text-center text-xl">{event.name_and_format}</p>
            </div>
          </>
        }
        subTitle={
          <>
            <div className="hidden sm:block">
              <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                <div className="mt-2 flex items-center capitalize sm:mt-0">
                  <LocationMarkerIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  {event.location}
                </div>
                <div className="mt-2 flex items-center sm:mt-0">
                  <CalendarIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  {event.dates}
                </div>
                {event.hashtag && (
                  <div className="mt-2 flex items-center sm:mt-0">
                    <HashtagIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                    <a
                      href={`https://twitter.com/search?q=%23${event.hashtag}&f=live`}
                      target="_blank"
                    >
                      {event.hashtag}
                    </a>
                  </div>
                )}
                <div className="mt-4 flex items-center sm:mt-0">
                  <StatusBadge status={event.status} />
                </div>
              </div>
              {Boolean(event.event_series.length) && (
                <div className="mt-3">
                  <SeriesBadges event={event} />
                </div>
              )}
              {Boolean(event.platform_url) && (
                <div className="mt-3">
                  <a
                    className="text-purple-haze-500 hover:text-purple-haze-900 text-sm font-normal underline"
                    target="_blank"
                    href={event.platform_url}
                  >
                    {onUltraSignup
                      ? 'View on UltraSignup'
                      : isVoteContest
                        ? 'View Voting Information'
                        : 'View Race Information'}
                  </a>
                </div>
              )}
            </div>
            <div className="sm:hidden">
              <div className="mt-3 flex justify-center text-sm">
                <div className="mr-4 flex items-center capitalize">
                  <LocationMarkerIcon className="mr-1 h-3 w-3 flex-shrink-0" aria-hidden="true" />
                  {event.location}
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="mr-1 h-3 w-3 flex-shrink-0" aria-hidden="true" />
                  {event.dates}
                </div>
              </div>
              <div className="my-4 text-center">
                <StatusBadge status={event.status} />
              </div>
              {Boolean(event.platform_url) && (
                <div className="my-2 text-center">
                  <a
                    className="hyperlink text-sm font-normal underline"
                    target="_blank"
                    href={event.platform_url}
                  >
                    {onUltraSignup
                      ? 'View on UltraSignup'
                      : isVoteContest
                        ? 'View Voting Information'
                        : 'View Race Information'}
                  </a>
                </div>
              )}
            </div>
          </>
        }
        sponsor={sponsor}
      />
      <MainContent>
        <PaddedContent>
          {Boolean(event.announcement) && (
            <FlashMessage type="announcement">
              <ReactMarkdown className="announcement">{event.announcement}</ReactMarkdown>
            </FlashMessage>
          )}
          {event.status === EventStatus.ResultsPosted && leaderboard.length > 0 && (
            <>
              <h2 className="mb-3 font-semibold">
                Fantasy Leaderboard
                <Tooltip>
                  Check out our{' '}
                  <Link className="underline" to="/faqs">
                    FAQs page
                  </Link>{' '}
                  to learn how scores are calculated.
                </Tooltip>
              </h2>
              <SectionDescription>
                The results are in! Check out the leaderboard to see whose picks rose above the
                rest!
              </SectionDescription>
              {user.isAuthenticated &&
                (eventUser.picks || []).some((p) => p.score !== null && p.score !== undefined) && (
                  <button
                    className="hyperlink mb-4 block text-sm underline"
                    onClick={() => setScorecardModalOpen(true)}
                  >
                    View Your Scorecard
                  </button>
                )}
              {communitySize > leaderboard.length && !loadFullLeaderboard && (
                <p className="mb-3 text-xs">
                  <button onClick={() => setLoadFullLeaderboard(true)} className="underline">
                    Show all scores
                  </button>
                </p>
              )}
              <div className="flex-row sm:flex">
                <Leaderboard users={leaderboard} communitySize={communitySize} />
              </div>
              {scorecardModalOpen && (
                <ScorecardModal
                  event={event}
                  onClose={() => setScorecardModalOpen(false)}
                  eventUser={eventUser}
                  communitySize={communitySize}
                />
              )}
            </>
          )}
          {event.status === EventStatus.Closed && (
            <FlashMessage type="notice">
              <LockClosedIcon
                className="text-zinc-900 mb-1 mr-1 inline h-4 w-4 flex-shrink-0"
                aria-hidden="true"
              />
              {isVoteContest ? (
                <span>
                  Voting has concluded. Results will be announced in early {eventYear + 1}!
                </span>
              ) : (
                <span>
                  Picks are locked in for this event. Come back after the race results have been
                  posted to see who was closest to the mark!
                </span>
              )}
            </FlashMessage>
          )}
          {event.status === EventStatus.Selected &&
            (isVoteContest ? (
              <FlashMessage type="notice">
                Voting is not yet open. You can browse the candidates below.
              </FlashMessage>
            ) : (
              <FlashMessage type="notice">
                This event isn’t yet open for making picks
                {event.entrants_count ? ', but you can browse the entrants list below' : ''}.
              </FlashMessage>
            ))}
          {event.status != EventStatus.Selected && (
            <>
              {!user.isAuthenticated && <h2 className="mb-3 font-semibold">Your Picks</h2>}
              {user.isAuthenticated && (
                <>
                  <h2 className="relative mb-3 font-semibold">
                    {userName[userName.length - 1] === 's' ? `${userName}’` : `${userName}’s`} Picks
                    <Tooltip>
                      Your picks are private unless you choose to share them.
                      {!isVoteContest && (
                        <span>
                          &nbsp;Your name and profile image will be used in our post-event
                          leaderboard (think Strava segments) if you make the cut.
                        </span>
                      )}
                    </Tooltip>
                  </h2>
                </>
              )}
              <SectionDescription>
                Use this section to manage your picks.
                {event.status === EventStatus.Open && (
                  <>
                    &nbsp;Once you’re happy with your selections, use the{' '}
                    <button
                      disabled={savingPicksDisabled}
                      className="ft-btn bg-purple-haze-500 disabled:bg-purple-haze-200 px-3 py-1 text-xs text-white hover:cursor-default"
                    >
                      Submit
                    </button>{' '}
                    button to save them! Make{' '}
                    <span className="font-medium">{maxPicksPerField} picks</span> per field!
                  </>
                )}
              </SectionDescription>
              {!user.isAuthenticated && (
                <FlashMessage type="info">
                  <UserIcon className="mb-1 mr-1 inline h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  <a
                    href="#"
                    className="underline"
                    onClick={() => loginAndRedirect(loginWithRedirect)}
                  >
                    Sign in
                  </a>{' '}
                  with email, Google, or Facebook to make your picks!
                </FlashMessage>
              )}
              <div className="mb-4">
                <CopyPicksButton
                  malePicks={malePicks}
                  femalePicks={femalePicks}
                  event={event}
                  isCommunity={false}
                />
                {event.status === EventStatus.Open && (
                  <>
                    <button
                      className="ft-btn haze-button border-purple-haze-800 disabled:border-purple-haze-300 mr-3 border px-3 py-2"
                      disabled={savingPicksDisabled}
                      onClick={() => submitPicks()}
                    >
                      {savingPicks ? (
                        'Submitting...'
                      ) : (
                        <>
                          <CloudUploadIcon className="ft-btn-icon" aria-hidden="true" />
                          Submit
                        </>
                      )}
                    </button>
                    <EventCountdown closesAt={event.closes_at} />
                  </>
                )}
                {submitPicksModalOpen && (
                  <PostSubmissionModal
                    isVoteContest={isVoteContest}
                    onClose={() => setSubmitPicksModalOpen(false)}
                    maxPicksPerField={maxPicksPerField}
                    picksCount={allPicks.length}
                    eventClosesAt={event.closes_at}
                  />
                )}
              </div>
              {user.isAuthenticated && !picksSaved && (
                <div>
                  <FlashMessage type="notice" small>
                    You’ve updated your picks! Remember to save them before you hit the trail!
                  </FlashMessage>
                </div>
              )}
              <div className="flex-row lg:flex">
                <PicksTable
                  actionsDisabled={actionsDisabled}
                  event={event}
                  picks={femalePicks}
                  heading="Women’s Picks"
                  movePick={movePick}
                  picksLoading={isLoadingUserPicks}
                  removePick={removePick}
                  maxPicksPerField={maxPicksPerField}
                  showAthleteInfoModal={showAthleteInfoModal}
                />
                <PicksTable
                  actionsDisabled={actionsDisabled}
                  event={event}
                  picks={malePicks}
                  heading="Men’s Picks"
                  movePick={movePick}
                  picksLoading={isLoadingUserPicks}
                  removePick={removePick}
                  maxPicksPerField={maxPicksPerField}
                  showAthleteInfoModal={showAthleteInfoModal}
                />
              </div>
              {false && (
                <div className="mb-8">
                  <h2 className="mb-3 font-semibold">Predict Winning Times</h2>
                  <SectionDescription>
                    Predict the winning time for each field! This is optional and won’t impact your
                    score, but it will be used for breaking ties! We'll also display a second
                    leaderboard after the race ranking your guesses. Like your picks, you can update
                    your guesses as many times as you like until the event is locked.
                  </SectionDescription>
                  <div>
                    <h3 className="mb-3 text-xs font-medium uppercase">Women's Race</h3>
                    <div className="my-4 flex">
                      <div className="mr-4 flex h-[45px]">
                        <input
                          type="number"
                          min={0}
                          max={200}
                          className="inline-block h-full w-[65px] text-sm"
                        />
                        <label className="bg-zinc-900 inline-block flex h-full place-items-center px-4 text-xs uppercase text-white">
                          Hours
                        </label>
                      </div>
                      <div className="mr-4 flex h-[45px]">
                        <input
                          type="number"
                          min={0}
                          max={59}
                          className="inline-block h-full w-[65px] text-sm"
                        />
                        <label className="bg-zinc-900 inline-block flex h-full place-items-center px-4 text-xs uppercase text-white">
                          Minutes
                        </label>
                      </div>
                      <div className="flex h-[45px]">
                        <input
                          type="number"
                          min={0}
                          max={59}
                          className="inline-block h-full w-[65px] text-sm"
                        />
                        <label className="bg-zinc-900 inline-block flex h-full place-items-center px-4 text-xs uppercase text-white">
                          Seconds
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isVoteContest && (
                <>
                  <h2 className="mb-3 font-semibold">
                    Community Picks
                    <Tooltip>
                      We use a rudimentary{' '}
                      <a
                        className="underline"
                        target="_blank"
                        href="https://en.wikipedia.org/wiki/Borda_count"
                      >
                        Borda count
                      </a>{' '}
                      to calculate these rankings.
                    </Tooltip>
                  </h2>
                  {event.status === EventStatus.Open && (
                    <div className="mb-8">
                      <FlashMessage type="info">
                        Community picks will be posted once all picks are locked in. Come back just
                        before the event starts to see the consensus predictions for each field!
                      </FlashMessage>
                    </div>
                  )}
                  {event.status !== EventStatus.Open &&
                    ([...femaleCommunityPicks, ...maleCommunityPicks].length ? (
                      <>
                        <SectionDescription>
                          Here are your top contenders as voted by the community.
                        </SectionDescription>
                        <button
                          className="hyperlink mb-6 block text-sm underline"
                          onClick={() =>
                            api(user.accessToken)
                              .downloadCommunityPicks(event.uuid)
                              .then((response) => {
                                response.blob().then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const link = document.createElement('a');
                                  link.style.display = 'none';
                                  link.href = url;
                                  link.setAttribute(
                                    'download',
                                    `${event.name} - ${event.dates} - Freetrail Fantasy Community Picks.csv`
                                  );
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                  window.URL.revokeObjectURL(url);
                                });
                              })
                          }
                        >
                          Download Aggregate Data
                        </button>
                        {communitySize > 1 && (
                          <p className="mb-4 text-xs">
                            Rankings based on picks submitted by {communitySize} community members.
                          </p>
                        )}
                        <div className="flex-row lg:flex">
                          <PicksTable
                            actionsDisabled={actionsDisabled}
                            event={event}
                            picks={femaleCommunityPicks}
                            heading="Women’s Picks"
                            picksLoading={isLoadingCommunityPicks}
                            communityPicks
                            comparisonPicks={femalePicks}
                            maxPicksPerField={maxPicksPerField}
                            showAthleteInfoModal={showAthleteInfoModal}
                          />
                          <PicksTable
                            actionsDisabled={actionsDisabled}
                            event={event}
                            picks={maleCommunityPicks}
                            heading="Men’s Picks"
                            picksLoading={isLoadingCommunityPicks}
                            communityPicks
                            comparisonPicks={malePicks}
                            maxPicksPerField={maxPicksPerField}
                            showAthleteInfoModal={showAthleteInfoModal}
                          />
                        </div>
                      </>
                    ) : (
                      !isLoadingCommunityPicks && (
                        <FlashMessage type="notice">
                          We need more data before we can show community picks for this event.
                        </FlashMessage>
                      )
                    ))}
                </>
              )}
            </>
          )}
          <h2 className="relative mb-3 font-semibold" id="entrants">
            {hasPerformanceEntrants ? 'Performances' : 'Athletes'}
            {!isVoteContest && (
              <Tooltip>
                Entrant data may lag. Visit{' '}
                <a className="underline" href={event.platform_url} target="_blank">
                  the source
                </a>{' '}
                for the up-to-date entrants list.
              </Tooltip>
            )}
          </h2>
          {isVoteContest ? (
            hasPerformanceEntrants ? (
              <SectionDescription>
                Filter the performance list by athlete name and field. Use the "Pick" button to add
                a performance to your picks. Click on an athlete’s name to learn more about their
                performance.
              </SectionDescription>
            ) : (
              <SectionDescription>
                Filter the athletes list by name, location, and field. Use the "Pick" button to add
                a runner to your picks. Click on an athlete’s name to view a summary of their{' '}
                {eventYear} results.
              </SectionDescription>
            )
          ) : (
            <SectionDescription>
              Filter the entrants list by name, location, and field. Use the "Pick" button to add a
              runner to your picks. Click on an athlete’s name to learn more about them.
            </SectionDescription>
          )}
          {athleteInfoModalOpen && selectedAthlete !== undefined && (
            <AthleteProfileModal
              athleteId={selectedAthlete.athlete_id}
              firstName={selectedAthlete.first_name}
              lastName={selectedAthlete.last_name}
              location={[selectedAthlete.city, selectedAthlete.state_or_country]
                .filter((v) => v)
                .join(', ')}
              notes={selectedAthlete.notes}
              onClose={() => setAthleteInfoModalOpen(false)}
              resultsYear={isVoteContest ? eventYear : undefined}
              resultId={isVoteContest ? selectedAthlete.result_id : undefined}
              itraId={selectedAthlete.itra_id}
              utmbId={selectedAthlete.utmb_id}
            />
          )}
          <form className="border-b-1 mb-2 pb-4" onSubmit={(e) => e.preventDefault()}>
            <div className="justify-left flex">
              <div className="mb-0 mr-3 xl:w-60">
                <label className="text-sm">
                  Filter {hasPerformanceEntrants ? 'Performances' : 'Athletes'} ({entrants.length})
                </label>
                <input
                  type="text"
                  placeholder="Search by name or location"
                  value={queryFilter}
                  onChange={(e) => doQueryFilter(e.target.value)}
                  className="form-select focus:outline-none
                          m-0
                          block
                          w-full
                          appearance-none
                          border
                          border-solid
                          border-gray-500 bg-white bg-clip-padding
                          bg-no-repeat p-3 text-sm
                          font-normal
                          text-gray-700
                          transition
                          ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700"
                />
              </div>
              <div className="mb-0 w-28 xl:w-60">
                <label className="text-sm">Field</label>
                <select
                  className="form-select focus:outline-none
                          m-0
                          block
                          w-full
                          appearance-none
                          border
                          border-solid
                          border-gray-500 bg-white bg-clip-padding
                          bg-no-repeat p-3 text-sm
                          font-normal
                          text-gray-700
                          transition
                          ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700"
                  aria-label="Select field"
                  onChange={(e) => doFieldFilter(e.target.value)}
                >
                  {Object.entries(FIELDS).map((e) => (
                    <option key={e[0]} value={e[0]}>
                      {e[1]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
          <div className="overflow-x-scroll border-b border-gray-300 shadow lg:overflow-hidden">
            <table className="ft-table">
              <thead>
                <tr>
                  <th scope="col" className="table-cell sm:hidden">
                    Pick
                  </th>
                  {isVoteContest ? (
                    hasPerformanceEntrants ? (
                      <>
                        <th scope="col">Athlete</th>
                        <th scope="col" className="hidden text-center sm:table-cell">
                          Nationality
                        </th>
                        <th scope="col">Race</th>
                        <th scope="col">Place</th>
                        <th scope="col">Time</th>
                        <th scope="col" className="text-center">
                          Course Record
                        </th>
                        <th className="text-center" scope="col">
                          Field
                        </th>
                      </>
                    ) : (
                      <>
                        <th scope="col">Name</th>
                        <th className="text-center" scope="col">
                          Nationality
                        </th>
                        <th scope="col">Location</th>
                        <th className="text-center" scope="col">
                          Field
                        </th>
                        <th scope="col">Team</th>
                      </>
                    )
                  ) : (
                    <>
                      <th scope="col">Name {onUltraSignup ? '(Results)' : ''}</th>
                      <th scope="col">
                        {event.platform === EventPlatform.UTMB ? 'Nationality' : 'Location'}
                      </th>
                      <th className="text-center" scope="col">
                        Field
                      </th>
                      <th scope="col">{onUltraSignup ? 'Age Group' : 'Category'}</th>
                      <th scope="col">
                        {event.rank_label ||
                          (onUltraSignup ? 'UltraSignup Rank (%)' : 'UTMB Index')}
                      </th>
                    </>
                  )}
                  <th scope="col" className="hidden sm:table-cell">
                    Pick
                  </th>
                </tr>
              </thead>
              <tbody>
                {entrants.map((entrant) => {
                  const entrantPicked = Boolean(allPicks.find((e) => e.id === entrant.id));
                  const addPickDisabled =
                    actionsDisabled ||
                    !entrant.started_race ||
                    (entrant.field === FIELD_FEMALE && femalePicksFull) ||
                    (entrant.field === FIELD_MALE && malePicksFull);

                  return (
                    <tr
                      key={entrant.id}
                      className={`h-12 ${
                        entrant.started_race ? 'hover:bg-gray-50' : 'bg-gray-200 line-through'
                      }`}
                    >
                      <PickTableCell
                        classNames="sm:hidden table-cell"
                        entrant={entrant}
                        entrantPicked={entrantPicked}
                        addPick={addPick}
                        addPickDisabled={addPickDisabled}
                        removePick={removePick}
                        removePickDisabled={actionsDisabled}
                      />
                      {isVoteContest ? (
                        hasPerformanceEntrants ? (
                          <>
                            <td className="td-compact py-3">
                              <div className="min-w-[180px] text-sm text-gray-900">
                                <EntrantName
                                  entrant={entrant}
                                  onClick={() => showAthleteInfoModal(entrant)}
                                  showImage
                                  largeImage
                                />
                              </div>
                            </td>
                            <td className="td-compact hidden sm:table-cell">
                              <div className="flex justify-center py-3">
                                <NationalityFlag country={entrant.athlete?.country} />
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="text-sm text-gray-900">
                                <a
                                  className="hyperlink underline"
                                  href={entrant.result.race.results_url}
                                  target="_blank"
                                >
                                  {entrant.result.race.name}
                                </a>
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="text-sm capitalize text-gray-900">
                                {withOrdinalSuffix(entrant.result.place_field)} {entrant.field} |{' '}
                                {withOrdinalSuffix(entrant.result.place_overall)} OA
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="text-sm capitalize text-gray-900">
                                {entrant.result.formatted_finish_time}
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="flex justify-center text-sm">
                                {entrant.result.course_record ? '👑' : ''}
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="text-center text-sm capitalize text-gray-900">
                                {entrant.field}
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="td-compact">
                              <div className="text-sm text-gray-900">
                                <EntrantName
                                  entrant={entrant}
                                  onClick={() => showAthleteInfoModal(entrant)}
                                  showImage
                                  largeImage
                                />
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="flex justify-center">
                                <NationalityFlag country={entrant.athlete?.country} />
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="text-sm capitalize text-gray-900">
                                {entrant.athlete?.location}
                              </div>
                            </td>
                            <td className="td-compact">
                              <div className="text-center text-sm capitalize text-gray-900">
                                {entrant.field}
                              </div>
                            </td>
                            <td className="td-compact py-3">
                              {Boolean(entrant.athlete?.teams?.length) ? (
                                <div>
                                  <AthleteTeams teams={entrant.athlete?.teams} />
                                </div>
                              ) : (
                                <div className="h-10" />
                              )}
                            </td>
                          </>
                        )
                      ) : (
                        <>
                          <td className="td-compact">
                            <div className="text-sm text-gray-900">
                              <EntrantName
                                entrant={entrant}
                                onClick={() => showAthleteInfoModal(entrant)}
                                showImage
                              />
                            </div>
                          </td>
                          <td className="td-compact">
                            <EntrantLocation event={event} entrant={entrant} />
                          </td>
                          <td className="td-compact">
                            <div className="text-center text-sm capitalize text-gray-900">
                              {entrant.field}
                            </div>
                          </td>
                          <td className="td-compact">
                            <div className="text-sm capitalize text-gray-900">
                              {entrant.age_group}
                            </div>
                          </td>
                          <td className="td-compact text-sm">
                            {onUltraSignup
                              ? entrant.pre_race_rank_percentage
                              : entrant.pre_race_rank_percentage
                                ? parseInt(entrant.pre_race_rank_percentage)
                                : ''}
                          </td>
                        </>
                      )}
                      <PickTableCell
                        classNames="hidden sm:table-cell"
                        entrant={entrant}
                        entrantPicked={entrantPicked}
                        addPick={addPick}
                        addPickDisabled={addPickDisabled}
                        removePick={removePick}
                        removePickDisabled={actionsDisabled}
                      />
                    </tr>
                  );
                })}
                {entrants.length === 0 && (
                  <tr>
                    <td className="td-compact py-3 text-sm">
                      {event.status === EventStatus.Selected
                        ? 'Entrants not yet available'
                        : 'No matching entries'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Event;
