import React from 'react';
import { Link } from 'react-router-dom';

const SeriesBadges = ({ event }) => {
  return (
    <>
      {event.event_series.map((series) => (
        <Link
          className="inline-flex bg-gray-500 px-3 py-1 text-xs font-normal leading-5 text-white hover:underline"
          to={`/series/${series.uuid}`}
          key={series.id}
          onClick={(e) => e.stopPropagation()}
        >
          {series.name}
        </Link>
      ))}
    </>
  );
};

export default SeriesBadges;
